aaaie.Xhr = {
  config: {
    cache: !aaaie.debug,
    shortCacheDuration: 10, //seconds
    longCacheDuration: 5, //minutes
    s3Regex: /^https:\/\/(((csaa-ds-releases.)*s3.amazonaws.com.*)|(csaa-ds-releases.*.csaa-insurance.aaa.com))/
  },
  currentURL: function() {
    return aaaie.Url.parse(document.location);
  },
  regexApiDatapower: '/api/v.*',
  activeCalls: {},
  shortCache: {},
  clearCacheKey: function(path) {
    function lsTest() {
      var test = 'test';
      try {
        sessionStorage.setItem(test, test);
        sessionStorage.removeItem(test);
        return true;
      } catch (e) {
        return false;
      }
    }
    var locStor = lsTest();
    if (locStor) delete sessionStorage[path];
    delete aaaie.Xhr.shortCache[path];
  },
  clearOldCache: function() {
    //loop through local storage entries and clear anything older than 20 minutes
  },
  pathAdder: function(prependPath, path, regexString) {
    var regexp = new RegExp(regexString);
    var pathSeg = regexp.exec(path);
    var url = '';
    if (pathSeg !== null && path === pathSeg[0]) {
      url = prependPath + path;
      // eslint-disable-next-line no-console
      console.log('datapower pathSeg: ' + pathSeg[0]);
      return url;
    } else {
      // eslint-disable-next-line no-console
      console.log('no datapower url: ' + path);

      return path;
    }
  },
  s3PathMatch: function(path) {
    return aaaie.Xhr.config.s3Regex.test(path);
  },
  getDataPowerURL: function() {
    return (
      (wms_customer_info.dataPowerUrl !== undefined &&
        wms_customer_info.dataPowerUrl.split('/api/v')[0]) ||
      ''
    );
  },
  getJSON: function(path, fn) {
    return aaaie.Xhr.get(path, 'application/json', { success: fn });
  },
  get: function(path, contentType, conf) {
    function lsTest() {
      var test = 'test';
      try {
        sessionStorage.setItem(test, test);
        sessionStorage.removeItem(test);
        return true;
      } catch (e) {
        return false;
      }
    }
    var locStor = lsTest();
    var dfd = aaaie.$.Deferred();
    var configuration = aaaie.$.extend(true, {}, aaaie.Xhr.config, conf);

    if (
      configuration.cache &&
      aaaie.Xhr.shortCache[path] &&
      moment
        .utc()
        .diff(
          moment.utc(parseInt(aaaie.Xhr.shortCache[path].timestamp, 10)),
          'seconds'
        ) < configuration.shortCacheDuration
    ) {
      // eslint-disable-next-line no-console
      console.log('recent call detected to: ' + path);
      dfd.resolve({
        data: JSON.parse(aaaie.Xhr.shortCache[path].json),
        status: 'from short cache'
      });
      return dfd.promise();
    }

    if (
      locStor &&
      sessionStorage[path] &&
      configuration.cache &&
      moment
        .utc()
        .diff(
          moment.utc(parseInt(sessionStorage[path + '-timestamp'], 10)),
          'minutes'
        ) < configuration.longCacheDuration
    ) {
      dfd.resolve({
        data: JSON.parse(sessionStorage[path]),
        status: 'from long cache'
      });
      return dfd.promise();
    }
    //check if a call to the same path is already in progress, if so, attach events to the existing dfd
    if (aaaie.Xhr.activeCalls[path]) {
      // eslint-disable-next-line no-console
      console.log('concurrent call detected to: ' + path);
      return aaaie.Xhr.activeCalls[path].promise();
    } else {
      aaaie.Xhr.activeCalls[path] = dfd;
      var settings = {
        url: path,
        origUrl: path,
        type: 'GET',
        contentType: contentType || 'application/json; charset=UTF-8',
        cache: configuration.cache
      };
      var isS3 = aaaie.Xhr.s3PathMatch(path);
      if (
        !isS3 &&
        typeof wms_customer_info !== 'undefined' &&
        wms_customer_info &&
        wms_customer_info.directaccess
      ) {
        settings.url = aaaie.Xhr.pathAdder(
          aaaie.Xhr.getDataPowerURL(),
          path,
          aaaie.Xhr.regexApiDatapower
        );
        // eslint-disable-next-line no-console
        console.log(settings.url);
        settings.headers = {
          Authorization: 'Bearer ' + wms_customer_info.Authorization,
          DIRECT_ACCESS_ID: wms_customer_info.regId,
          DIRECTACCESSID: wms_customer_info.regId,
          DIRECTACCESS: true
        };
      }

      if (undefined === settings.headers) {
        settings.headers = {};
      }

      settings.headers['Strict-Transport-Security'] =
        'max-age=31536000; includeSubDomains';
      settings.headers['X-XSS-Protection'] = '1; mode=block';
      settings.headers['X-Content-Type-Options'] = 'nosniff';
      settings.headers['X-Frame-Options'] = 'SAMEORIGIN';


      aaaie.$.ajax(settings)
        .done(function(data, status, xhr) {
          delete aaaie.Xhr.activeCalls[this.origUrl];
          //add to short cache and long cache
          aaaie.Xhr.shortCache[this.origUrl] = {
            timestamp: Date.now(),
            json: JSON.stringify(data)
          };
          if (locStor && this.cache) {
            try {
              sessionStorage[this.origUrl] =
                aaaie.Xhr.shortCache[this.origUrl].json;
              sessionStorage[this.origUrl + '-timestamp'] = Date.now();
            } catch (e) {
              try {
                sessionStorage.clear();
              } catch (ex) {
                // eslint-disable-next-line no-empty
              }
            }
          }
          dfd.resolve({ data: data, status: status, xhr: xhr });
        })
        .fail(function(xhr, status, error) {
          delete aaaie.Xhr.activeCalls[this.origUrl];
          dfd.reject({ xhr: xhr, status: status, error: error });
        });
    }

    return dfd.promise();
  },
  post: function(path, options) {
    var dfd = aaaie.$.Deferred();
    if (
      typeof wms_customer_info !== 'undefined' &&
      wms_customer_info &&
      wms_customer_info.directaccess
    ) {
      path = aaaie.Xhr.pathAdder(
        aaaie.Xhr.getDataPowerURL(),
        path,
        aaaie.Xhr.regexApiDatapower
      );
      // eslint-disable-next-line no-console
      console.log(path);
      options.url = path;
      options.headers = {
        Authorization: 'Bearer ' + wms_customer_info.Authorization,
        DIRECT_ACCESS_ID: wms_customer_info.regId,
        DIRECTACCESSID: wms_customer_info.regId,
        DIRECTACCESS: true
      };
    }

    if (undefined === options.headers) {
      options.headers = {};
    }

    options.headers['Strict-Transport-Security'] =
      'max-age=31536000; includeSubDomains';
    options.headers['X-XSS-Protection'] = '1; mode=block';
    options.headers['X-Content-Type-Options'] = 'nosniff';
    options.headers['X-Frame-Options'] = 'SAMEORIGIN';


    aaaie.$.ajax(path, options)
      .done(function(data, status, xhr) {
        dfd.resolve({ data: data, status: status, xhr: xhr });
      })
      .fail(function(xhr, status, error) {
        dfd.reject({ xhr: xhr, status: status, error: error });
      });

    return dfd.promise();
  }
};
