aaaie.StringFormatting = {
  changeNameToUpperCase: function(name) {
    if (!name) {
      return name;
    } else {
      var l = name.split(' ');
      for (var i = 0; i < l.length; i++) {
        l[i] = l[i].charAt(0).toUpperCase() + l[i].slice(1).toLowerCase();
      }
      return l.join(' ');
    }
  }
};
