aaaie.Logger = {};

(function(log) {
  //'use strict';
  var logStore = {},
    WARN = 'WARN',
    ERROR = 'ERROR',
    DEBUG = 'DEBUG';

  var pushLog = function(type) {
    return function(msg, context) {
      if (!logStore[type]) {
        logStore[type] = {};
      }
      var time = +new Date();
      logStore[type][time] = msg;
      if (aaaie.config.showLogging || aaaie.debug) {
        writeToConsole(type, time, msg, context);
      }
    };
  };

  log.warn = pushLog(WARN);
  log.error = pushLog(ERROR);
  log.debug = pushLog(DEBUG);

  var writeToConsole = function(type, time, msg, context) {
    // eslint-disable-next-line no-console
    if (console && console.log) {
      time = new Date(time).toTimeString();
      msg = type + ': [' + time + '] ' + msg;
      if (context) {
        // eslint-disable-next-line no-console
        console.log(msg, context);
      } else {
        // eslint-disable-next-line no-console
        console.log(msg);
      }
    } else {
      // there is no else. you're screwed. Use a better browser!
    }
  };

  log.retrieve = function(type) {
    return type ? logStore[type] : logStore;
  };

  log.retrieveDebug = function() {
    return log.retrieve(DEBUG);
  };

  log.retrieveError = function() {
    return log.retrieve(ERROR);
  };

  log.retrieveWarn = function() {
    return log.retrieve(WARN);
  };

  // Helpful for creating aliases
  log.create = function() {
    return log;
  };
})(aaaie.Logger);

aaaie.Logger.warn = deprecate(
  aaaie.Logger.warn,
  'aaaie.Logger.warn() is deprecated. Use console.warn() instead.'
);
aaaie.Logger.error = deprecate(
  aaaie.Logger.error,
  'aaaie.Logger.error() is deprecated. Use console.error() instead.'
);
aaaie.Logger.debug = deprecate(
  aaaie.Logger.debug,
  'aaaie.Logger.debug() is deprecated. Use console.debug() instead.'
);
aaaie.Logger.retrieve = deprecate(
  aaaie.Logger.retrieve,
  'Avoid aaaie.Logger.retrieve().'
);
aaaie.Logger.retrieveDebug = deprecate(
  aaaie.Logger.retrieveDebug,
  'Avoid aaaie.Logger.retrieveDebug().'
);
aaaie.Logger.retrieveError = deprecate(
  aaaie.Logger.retrieveError,
  'Avoid aaaie.Logger.retrieveError().'
);
aaaie.Logger.retrieveWarn = deprecate(
  aaaie.Logger.retrieveWarn,
  'Avoid aaaie.Logger.retrieveWarn().'
);
aaaie.Logger.create = deprecate(
  aaaie.Logger.create,
  'Avoid aaaie.Logger.create().'
);
