/**
 * Implementation of templating features with the Handlebars library.
 *
 * @type {Object}
 */
aaaie.Templating = {
  config: {
    templatePath: '' //path to template files on server
  },

  //for renderFromServer cache
  templateCache: {},

  /**
   * Alias to the Handlebars compiler
   * @type {[type]}
   */
  compile: Handlebars.compile,

  /**
   * Retrieves a template from a Template element. Looks for template in current
   * context and if not found looks within an HTML Import'd context.
   *
   * Will assure the content property exists.
   */
  getTemplate: function(templateName) {
    // a context for HTML Import'd scripts (attempts polyfill then defaults to native)
    var ctx =
      document._currentScript.ownerDocument ||
      document.currentScript.ownerDocument;
    var template = aaaie.$(templateName, ctx)[0];
    if (template) {
      //aaaie.Elements.polyfillTemplate(template);
      return template;
    }
    return null;
  },

  /**
   * Renders a template with the data provided.
   *
   * @param  {String} source  string based template or precompiled function
   * @param  {Object} context data for the template
   * @return {String}         fully rendered HTML with data
   */
  render: function(source, context) {
    'use strict';
    if ('function' === typeof source) {
      return source(context);
    }
    var template = Handlebars.compile(source);
    return template(context);
  },

  /**
   * Renders a template with the data provided into an element.
   *
   * If provided all three arguments it will compile the template with
   * the context provided before injecting it into the element.
   *
   * @param  {Object} elem     string identififer for an element or a jQuery object
   * @param  {String} template string rendering of the template to inject
   * @param  {Object} context  data to use within the template
   */
  renderInto: function(elem, template, context) {
    'use strict';
    if ('string' === typeof elem) {
      elem = aaaie.$(elem);
    }
    if (!context) {
      elem.html(template);
    } else if ('function' === typeof template) {
      elem.html(template(context));
    } else {
      elem.html(this.render(template, context));
    }
  },

  /**
   * Alias to the Handlebars register helper
   * @name {String}
   * @fn {Function}
   * @inverse {Bool}
   */
  addHelper: function(name, fn, inverse) {
    Handlebars.registerHelper(name, fn, inverse);
  },

  renderFromServer: function(tmpl_name, context, options) {
    var config = aaaie.Templating.config;
    aaaie.$.extend(true, config, options);
    if (!this.tmpl_cache) {
      this.tmpl_cache = {};
    }
    //TODO make template path configurable
    if (!this.tmpl_cache[tmpl_name]) {
      var tmpl_url = config.templatePath + '/' + tmpl_name + '.html';

      var tmpl_string = '';
      aaaie.$.ajax({
        url: tmpl_url,
        method: 'GET',
        async: false,
        success: function(data) {
          tmpl_string = data;
        }
      });

      this.tmpl_cache[tmpl_name] = this.compile(tmpl_string);
    }

    return this.tmpl_cache[tmpl_name](context);
  },

  renderIntoFromServer: function(elem, tmpl_name, context, options) {
    elem.html(this.renderFromServer(tmpl_name, context, options));
  }
};

aaaie.Templating.addHelper = deprecate(
  aaaie.Templating.addHelper,
  'aaaie.Templating.addHelper() is deprecated. Use Handlebars.registerHelper() instead.'
);
