/*
 * AAAIE Date Module
 */
aaaie.Date = {
  config: {
    formatAttribute: 'data-aaaie-date-format',
    debug: aaaie.debug
  },

  /**
   * Returns the current Date in the specified format
   */
  now: function(format) {
    return moment().format(format);
  },

  /**
   * Returns the provided Date in the specified format
   */
  format: function(date, format) {
    return moment(date).format(format);
  },

  /**
   * Returns an incremented date
   */
  add: function(_moment, number, unit) {
    return _moment.add(number, unit);
  },

  /**
   * Returns a decremented date
   */
  subtract: function(_moment, number, unit) {
    return _moment.subtract(number, unit);
  },

  /**
   * Changes the date format from MM/dd/YYYY to YYYY-mm-dd
   */
  formatDateFromSlashMmDdYyyyToDashYyyyMmDd: function(date) {
    if (!date) return 'NOF';
    var year = date.substr(6, 4);
    var month = date.substr(0, 2);
    var day = date.substr(3, 2);
    return year + '-' + month + '-' + day;
  }
};

aaaie.Date.now = deprecate(
  aaaie.Date.now,
  'aaaie.Date.now() is deprecated. Use moment().format() instead.'
);
aaaie.Date.format = deprecate(
  aaaie.Date.format,
  'aaaie.Date.format() is deprecated. Use moment().format() instead.'
);
aaaie.Date.add = deprecate(
  aaaie.Date.add,
  'aaaie.Date.add() is deprecated. Use moment.add() instead.'
);
aaaie.Date.subtract = deprecate(
  aaaie.Date.subtract,
  'aaaie.Date.subtract() is deprecated. Use moment.subtract() instead.'
);

/**
 * Short Date Templating helper
 */
Handlebars.registerHelper('shortDate', function(date) {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  return (
    date.getUTCMonth() +
    1 +
    '/' +
    date.getUTCDate() +
    '/' +
    date.getUTCFullYear()
  );
});

aaaie.$(document).ready(function() {
  'use strict';

  /**
   * Helper function that will inspect the format attribute and then either
   * format the value of the INPUT or the HTML of the ELEMENT.
   */
  var setFormat = function(element) {
    var isInput = !element;
    var format = aaaie.$(this).attr(aaaie.Date.config.formatAttribute);
    var val = isInput ? aaaie.$(this).val() : aaaie.$(this).html();
    if (!isNaN(Date.parse(val))) {
      if (isInput) {
        aaaie.$(this).val(aaaie.Date.format(val, format));
      } else {
        aaaie.$(this).html(aaaie.Date.format(val, format));
      }
    } else {
      if (aaaie.Date.config.debug) {
        aaaie.Logging.debug('Failed to format the value as Date: ' + val);
      }
    }
  };

  /**
   * For all elements that are not INPUT format based on attribute
   */
  aaaie
    .$('[' + aaaie.Date.config.formatAttribute + ']:not(input)')
    .each(function() {
      setFormat.call(this, true);
    });

  /**
   * For all INPUT elements format the values based on attribute
   */
  aaaie.$('input[' + aaaie.Date.config.formatAttribute + ']').each(function() {
    setFormat.call(this);
  });

  /**
   * For all INPUT elements that have a format attribute update the value
   * on change event.
   */
  aaaie
    .$('form')
    .on(
      'change',
      'input[' + aaaie.Date.config.formatAttribute + ']',
      function() {
        setFormat.call(this);
      }
    );
});

/*
 * Shim to assure Date.now exists in all browsers
 */
Date.now =
  Date.now ||
  function() {
    return +new Date();
  };
