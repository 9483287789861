/**
 * Implementation of FORM validations using the h5Validate jQuery plugin.
 *
 * @type {Object}
 */
aaaie.Validations = {
  addPatterns: aaaie.$.h5Validate.addPatterns,
  // eslint-disable-next-line no-control-regex
  email: /^((([a-zA-Z]|\d)+([a-zA-Z]|\d|[!#$%&'*+\-/=?^_`{|}~.]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-zA-Z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/,
  policy: /^\s*\w*\s*$/,
  ssn: /^\s*\d{3}\s*-\s*\d{2}\s*-\s*\d{4}\s*$/,
  postalCode: /^\s*\d{5}-?(\d{4})?\s*$/,
  phoneNumber: /^\(\d{3}\)\s\d{3}-\d{4}$/,
  phoneWithDots: /^\d{3}\.\d{3}\.\d{4}$/,
  creditCardAll: /\b(?:\d[ -]*?){13,16}\b/,
  creditCardVisaMC: /^(?:4[0-9]{3}(-[0-9]{4}){2}-[0-9](?:[0-9]{3})?|5[1-5][0-9]{2}(-[0-9]{4}){3}?|2[1-5][0-9]{2}(-[0-9]{4}){3})$/,
  quoteWildcardSearch: '^[a-zA-Z0-9 ,-]+$',
  datemmddyyyy: /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/,
  name: /^[a-z ,.'-]+$/,
  numericOnly: /^[0-9]+$/,
  time: /^(((1[012])|0[0-9]):([0-5][0-9])) (AM|PM)$/,
  twoLetterState: /^((A[LKSZR])|(C[AOT])|(D[EC])|(F[ML])|(G[AU])|(HI)|(I[DLNA])|(K[SY])|(LA)|(M[EHDAINSOT])|(N[EVHJMYCD])|(MP)|(O[HKR])|(P[WAR])|(RI)|(S[CD])|(T[NX])|(UT)|(V[TIA])|(W[AVIY]))$/,
  errorsList: [],
  focusedInputValidationError: false,

  config: {
    classPrefix: 'aaaie-',
    debug: aaaie.debug,

    click: true,
    patternVar: 'aaaie-pattern',
    maskAttribute: 'aaaie-mask',
    validationTypeAttribute: 'aaaie-validationType',
    maskPlaceHolder: '_',
    errorAttribute: 'error-id',
    linkAttribute: 'aaaie-validations-link',

    validCallback: function(context) {
      //, validity){
      'use strict';
      var input = aaaie.$(context.element);
      var index = aaaie.Validations.errorsList.indexOf(input.attr('id'));
      if (index > -1) {
        aaaie.Validations.errorsList.splice(index, 1);
      }
      var errorMsg = input.next('.alert-error');
      if (errorMsg) {
        if (!input.attr('data-' + aaaie.Validations.config.errorAttribute)) {
          errorMsg.remove();
        } else {
          errorMsg.hide();
        }
      }
    },

    invalidCallback: function(context) {
      //, validity){
      'use strict';
      var input = aaaie.$(context.element);
      var index = aaaie.Validations.errorsList.indexOf(input.attr('id'));
      if (index < 0) {
        aaaie.Validations.errorsList.push(input.attr('id'));
      }
      var msg = '';
      //ZA: added to enable multiple error messages
      if (
        (!context.isForm && !context.valueMissing) ||
        context.isFormSubmitDone
      ) {
        if (
          input.data('aaaie-validations-error-list') &&
          input.data('aaaie-validations-error-num') >= 0 &&
          !context.valueMissing
        ) {
          if (
            input.data('aaaie-validations-error-list').length >
            input.data('aaaie-validations-error-num')
          ) {
            msg = input.data('aaaie-validations-error-list')[
              input.data('aaaie-validations-error-num')
            ];
          } else if (input.attr('required')) {
            msg = input.attr('data-error-message');
          } else if (aaaie.debug) {
            // eslint-disable-next-line no-console
            console.log(
              'Validations: Custom error message not found in list.',
              false
            );
          }
        } else {
          msg = input.attr('data-error-message');
        }
      } else if (
        context.valueMissing &&
        (input.next('.alert-error').length === 0 ||
          aaaie.$(input.next('.alert-error')[0]).is(':hidden'))
      ) {
        //unmark field
        input
          .removeClass('ui-state-error')
          .removeClass('ui-state-valid')
          .removeClass('aaaie-active');
        //input.form.find('#' + input.element.id).removeClass('ui-state-error').removeClass('ui-state-valid');
      }

      if (
        msg &&
        !input.attr('data-' + aaaie.Validations.config.errorAttribute)
      ) {
        var errorMsg = input.next('.alert-error');
        if (!errorMsg.length) {
          errorMsg = aaaie.$('<div>', {
            class: 'alert alert-error error-icon'
          });
          errorMsg.insertAfter(input);
        }
        errorMsg.html(msg);
        errorMsg.trigger('validationError');
      }
    }
  },

  inputFailsValidation: function inputFailsValidation(classList) {
    return /(^| )ui-state-error( |$)/i.test(classList);
  },

  initSubmitButtonAvailability: function initSubmitButtonAvailability(formSelector, buttonsSelector) {
    var _this = aaaie.Validations;
    var inputFields = aaaie.$(formSelector).find('input');
    _this.errorsList = [];

    inputFields.bind('validated', function(e){
        if(e.currentTarget.type === 'checkbox') {
          var parent = aaaie.$(e.currentTarget.parentElement);
          if(_this.inputFailsValidation(e.currentTarget.classList)){
            parent.addClass('ui-state-error');
          } else {
            parent.removeClass('ui-state-error');
          }
        }
        _this.refreshSubmitButtons(buttonsSelector);
      });
  },

  refreshSubmitButtons: function refreshSubmitButtons(buttons) {
    var _this = aaaie.Validations;
    if(!buttons) {
      return;
    }
    var submitButtons = aaaie.$(buttons);
    if(_this.errorsList.length === 0) {
      submitButtons.attr('disabled', false).removeClass('disabled');
    } else {
      submitButtons.attr('disabled', true).addClass('disabled');
    }
  },

  clear: function clear(id){
    var parent = aaaie.$('#'+id).parent();
    parent.find('.ui-state-error').removeClass('ui-state-error');
    parent.find('.alert-error').remove();
    aaaie.Validations.clearValidationError(id);
  },

  clearValidationError: function clearValidationError(id) {
    var errorList = aaaie.Validations.errorsList;
    var validationErrorIndex = errorList.indexOf(id);
    var element = document.getElementById(id);

    if(validationErrorIndex >= 0) {
      errorList.splice(validationErrorIndex, 1);
      if(element) {
        element.dispatchEvent(new Event('validated'));
      }
    }
  },

  clearAll: function(buttons) {
    var _this = aaaie.Validations;
    aaaie.$('.ui-state-error').removeClass('ui-state-error');
    aaaie.$('.alert-error').remove();
    _this.errorsList = [];
    _this.refreshSubmitButtons(buttons);
  },

  setInlineErrorMessage: function setInlineErrorMessage(elementId, message) {
    var element = aaaie.$('#' + elementId);
    if (element.hasClass('ui-state-error')){
      element.next('div').remove();
    }
    var errorMessage = "<div class='alert alert-error error-icon'>"+message+"</div>";
    var errorMessageElement = aaaie.$(errorMessage);
    element.addClass('ui-state-error');
    errorMessageElement.insertAfter(element);
    aaaie.Validations.errorsList.push(elementId);
    element.on('focus', function() {
      errorMessageElement.remove();
      element.removeClass('ui-state-error');
      aaaie.Validations.clearValidationError(elementId);
    });
  },

  addErrorMessage: function(element, message) {
    if (aaaie.$.isArray(aaaie.$(element).data('aaaie-validations-error-list')))
      aaaie.$(element).data('aaaie-validations-error-list').push(message);
    else if (!aaaie.$(element).data('aaaie-validations-error-list')) {
      aaaie.$(element).data('aaaie-validations-error-list', [message]);
    }
  },

  setErrorMessages: function(element, messages) {
    if (aaaie.$.isArray(messages))
      aaaie.$(element).data('aaaie-validations-error-list', messages);
    else if (aaaie.debug) {
      // eslint-disable-next-line no-console
      console.log(
        'Error adding error messages to element, messages must be an array.',
        false
      );
    }
  },

  masks: {
    shortDate: '99/99/9999',
    phoneNumber: '(999) 999-9999',
    phoneNumberWithExt: '(999) 999-9999? x99999',
    ssn: '999-99-999',
    postalCode: '99999?-9999',
    shortPostalCode: '99999',
    creditCard: '9999-9999-9999-9?999',
    monthYear: '99/99?99'
  },

  maskSettings: {
    placeholder: '_',
    completed: function(maskName) {
      aaaie.$(this).trigger('maskValidated', [this]);
      if (aaaie.debug) {
        // eslint-disable-next-line no-console
        console.log('Mask validated', [maskName, aaaie.$(this)[0]]);
      }
    }
  },

  addCustomDefinition: function(symbol, definition) {
    aaaie.$.mask.definitions[symbol] = definition;
  },

  addMask: function(name, mask) {
    if (!this.masks.hasOwnProperty(name)) this.masks[name] = mask;
  },

  addMasks: function(maskList) {
    for (var key in maskList) {
      if (!this.masks.hasOwnProperty(key)) this.masks[key] = maskList[key];
    }
  },

  //TO DO??: add attribute to define custom mask declaratively
  applyMask: function(source, maskName) {
    if (aaaie.Validations.masks[maskName]) {
      if (aaaie.Validations.config.maskPlaceHolder)
        aaaie.Validations.maskSettings.placeHolder =
          aaaie.Validations.config.maskPlaceHolder;
      aaaie
        .$(source)
        .mask(
          aaaie.Validations.masks[maskName],
          aaaie.Validations.maskSettings
        );
    } else if (aaaie.debug) {
      // eslint-disable-next-line no-console
      console.log(
        'Mask name not found. Call addMask(name, pattern) to add a custom mask.',
        false
      );
    }
  },

  isFormValid: function(form) {
    'use strict';
    if (form.h5Validate) {
      return form.h5Validate('allValid');
    } else {
      return aaaie.$(form).h5Validate('allValid');
    }
  },

  serverError: function(jsonResponse) {
    'use strict';
    var messageContainer = aaaie.$('.error-messages'),
      errors = jsonResponse.errors,
      errorsList = '<ul>';
    for (var i = 0; i < errors.length; i++) {
      var error = errors[i];
      errorsList += '<li>' + error + '</li>';
    }
    errorsList += '</ul>';
    messageContainer.html(errorsList);
  }
};

(function(Validations, aaaie_$) {
  'use strict';
  aaaie_$.h5Validate.addPatterns({
    policy: Validations.policy,
    ssn: Validations.ssn,
    postalCode: Validations.postalCode,
    phoneNumber: Validations.phoneNumber,
    phoneWithDots: Validations.phoneWithDots,
    creditCardAll: Validations.creditCardAll,
    creditCardVisaMC: Validations.creditCardVisaMC,
    quoteWildcardSearch: Validations.quoteWildcardSearch,
    datemmddyyyy: Validations.datemmddyyyy,
    numericOnly: Validations.numericOnly,
    name: Validations.name,
    email: Validations.email,
    time: Validations.time,
    twoLetterState: Validations.twoLetterState,

    dateOfBirth: function() {
      var currentDate = new Date(),
        birthDate = new Date(aaaie_$(this).val());
      return birthDate < currentDate;
    }
  });

  aaaie_$('form')
    .bind('instance', function(evt, instance) {
      if (aaaie.debug) {
        // eslint-disable-next-line no-console
        console.log('Validation instance', instance);
      }
    })
    .bind('validated', function(evt, validity) {
      if (aaaie.debug) {
        // eslint-disable-next-line no-console
        console.log('Input Validated', validity);
      }
    })
    .bind('formValidated', function(evt, validity) {
      if (aaaie.debug) {
        // eslint-disable-next-line no-console
        console.log('Form Validated', validity);
      }
    });
})(aaaie.Validations, aaaie.$);

/*
  Initialize validation on all FORM elements.
*/
aaaie.$(document).ready(function() {
  'use strict';
  aaaie
    .$('[data-' + aaaie.Validations.config.maskAttribute + ']')
    .not(
      '[data-' +
        aaaie.Validations.config.validationTypeAttribute +
        '!="Validate"]'
    )
    .each(function() {
      aaaie.Validations.applyMask(
        this,
        aaaie.$(this).attr('data-' + aaaie.Validations.config.maskAttribute)
      );
    });
  aaaie
    .$('[data-' + aaaie.Validations.config.validationTypeAttribute + '="Mask"]')
    .each(function() {
      aaaie.$(this).addClass('novalidate');
    });
  //aaaie.$('[data-' + aaaie.Validations.config.linkAttribute + ']').each(function() {aaaie.Validations.linkElements(this, aaaie.$(this).attr('data-' + aaaie.Validations.config.linkAttribute));});
  aaaie.$('form').h5Validate(aaaie.Validations.config);
});
