aaaie.$(document).ready(function() {
  $('html').addClass(detectBrowser());
  addClickEventForSliderOnChrome();
});

var detectBrowser = function() {
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE_' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR\/(\d+)/);
    if (tem !== null) return 'Opera_' + tem[1];
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) !== null) M.splice(1, 1, tem[1]);
  return M.join('_');
};

detectBrowser = deprecate(
  detectBrowser,
  'detectBrowser() is deprecated. Avoid browser detection.'
);

var addClickEventForSliderOnChrome = function() {
  if (detectBrowser().toLowerCase().indexOf('chrome') > -1) {
    $('body').on('mousedown', '.bx-viewport a', function() {
      if ($(this).attr('href') && $(this).attr('href') != '#') {
        var target = $(this).attr('target') ? $(this).attr('target') : '_self';
        window.open($(this).attr('href'), target);
      }
    });
  }
};
