aaaie.Charts = {
  barChart: function(options) {
    var chart;
    if (!options) {
      throw 'No options provided.';
    }
    try {
      chart = new Highcharts.Chart(options);
    } catch (hex) {
      if (hex.indexOf('error #13')) {
        throw "You don't have a DOM element.";
      }
      throw 'Some unidentified mess.';
    }
    return chart;
  }
};

aaaie.Charts.barChart = deprecate(
  aaaie.Charts.barChart,
  'aaaie.Charts.barChart() is deprecated. Use Highcharts.Chart() instead.'
);
