aaaie.Search = {
  config: {
    templatePath: '', //path to template files on server
    searchUrl:
      '/wps/proxy/https/pit-soaservices.tent.trt.csaa.pri:2070/WebEnterpriseSearch/csaigsearch/customerSearch.action',
    autoCompleteUrl:
      '/wps/proxy/https/pit-soaservices.tent.trt.csaa.pri:2070/WebEnterpriseSearch/csaigsearch/autoComplete.action',
    searchResultsHandler: '',
    searchResultsErrorHandler: '',
    redirectHandler: '',
    typeAheadLimit: 10,
    staticFacetLabel: true,
    displayStickyFilters: false,
    sameSearchParam: false,
    portal: '',
    searchInProgress: false,
    minLength: 3
  },

  membershipNumberMatched: function(
    customer,
    membershipNumber,
    dataMembershipNumber,
    policyStatus,
    prodTypeCode
  ) {
    if (
      customer &&
      (membershipNumber || dataMembershipNumber) &&
      prodTypeCode === 'MSHP'
    ) {
      return (
        (customer.memNum &&
          membershipNumber &&
          customer.memNum === membershipNumber) ||
        (customer.memNum &&
          dataMembershipNumber &&
          customer.memNum === dataMembershipNumber) ||
        $.grep(customer.partyRoles, function(partyRole) {
          return (
            (membershipNumber &&
              partyRole.sourceId.indexOf(membershipNumber) > -1) ||
            (dataMembershipNumber &&
              partyRole.sourceId.indexOf(dataMembershipNumber) > -1)
          );
        }).length > 0
      );
    }
    return false;
  },

  getPrimaryInsuredPerson: function(
    firstInsuredList,
    secondInsuredList,
    sourceSystem
  ) {
    var insuredList =
      firstInsuredList && firstInsuredList.length > 0
        ? firstInsuredList
        : secondInsuredList;
    var primaryInsured = $.grep(insuredList, function(i) {
      return (
        $.grep(i.partyRoles, function(pr) {
          return sourceSystem === 'PAS'
            ? pr.isPrimaryInsured === 'Y'
            : pr.roleType.indexOf('INSURED') > -1 && pr.roleStatus === 'ACTIVE';
        }).length > 0
      );
    });
    return primaryInsured[0] || insuredList[0];
  },

  formatResults: function(data, options) {
    var results = {},
      idx,
      pIns,
      dataMembershipNumber,
      memNum = aaaie.Search.getParameterByName('memNum');

    var memNumID = $('#memNum');
    memNumID.data('data-secondSearchMatch', false);

    if (memNum) {
      if (memNumID.data('data-isSecondSearch')) {
        dataMembershipNumber = '';
      } else {
        dataMembershipNumber = memNum;
      }
      memNumID.data('memNum', dataMembershipNumber);
    }

    $.each(data.searchedPolicies, function(index, value) {
      var ins = $.grep(value.partyList, function(n) {
        // AS: Check if the search is by membership id by looking at the URL parameter, then verify if its second
        // search which will happen in the case of membership id search, then check if we have found the match
        // and want to not fill up the array with other data.
        if (
          aaaie.Search.getParameterByName('memNum') ||
          memNumID.data('data-isSecondSearch') ||
          memNumID.data('data-secondSearchMatch')
        ) {
          if (
            aaaie.Search.membershipNumberMatched(
              n,
              memNum,
              dataMembershipNumber,
              value.policyStatus,
              value.prodTypeCode
            )
          ) {
            // AS : If we have found the data with the right membership id then calling the function to
            // redirect with the data.
            if (options.memNum) {
              aaaie.Search.membershipRedirect(n, options);
            }
            //aaaie.Search.membershipRedirect(n, options);
            return n;
          }
        } else {
          return (
            $.grep(n.partyRoles, function(n) {
              return (
                n.roleType.indexOf('INSURED') > -1 ||
                n.roleType.indexOf('LISTED') > -1 ||
                n.roleType.indexOf('OCCASIONAL') > -1 ||
                n.roleType.indexOf('PRIMARY MEMBER') > -1 ||
                n.roleType.indexOf('ASSOCIATE MEMBER')
              );
            }).length > 0
          );
        }
      });
      if (ins.length > 0) {
        var sortedLast =
          value.sortedFields['1'].length > 0
            ? value.sortedFields['1']
            : value.sortedFields['3'];
        var sortedFirst =
          value.sortedFields['2'].length > 0
            ? value.sortedFields['2']
            : value.sortedFields['4'];
        var sortedIns = $.grep(ins, function(n) {
          return n.lastName == sortedLast && n.firstName == sortedFirst;
        });
        if (!sortedIns || sortedIns.length === 0) {
          sortedIns = $.grep(ins, function(n) {
            return n.lastName == sortedLast;
          });
        }

        // If its search by membership use the ins data object.
        if (aaaie.Search.getParameterByName('memNum')) {
          pIns = aaaie.Search.getPrimaryInsuredPerson(
            ins,
            sortedIns,
            value.sourceSystem
          );
        } else {
          pIns = aaaie.Search.getPrimaryInsuredPerson(
            sortedIns,
            ins,
            value.sourceSystem
          );
        }

        // AS: If the memNum was the previous search then check for the memNum of each of the policies
        // and match it, if it doesn't match then do not show the result.

        if (memNumID.data('data-isSecondSearch')) {
          if (
            aaaie.Search.membershipNumberMatched(
              pIns,
              memNum,
              dataMembershipNumber,
              value.policyStatus,
              value.prodTypeCode
            )
          ) {
            // AS : This means we got the search result based on our
            idx = 'policy-' + pIns.mdmid;
            memNumID.data('data-isSecondSearch', '');
            memNumID.data('data-secondSearchMatch', true);
          } else {
            data.totalHits -= 1;
            return;
          }
        } else {
          idx = 'policy-' + pIns.mdmid;
        }

        if (!results[idx]) {
          results[idx] = {};
          results[idx].insured = pIns;
          //TODO remove the consumers array from the policies ZA
          results[idx].policies = [value];
          results[idx].address = [];
          results[idx].additional = [];
          results[idx].viewPolicyUrl = viewPolicyUrl;
          for (var i = 0; i < pIns.partyAddress.length; i++) {
            if (pIns.partyAddress[i].addressType.toLowerCase() == 'mailing') {
              results[idx].address.push(pIns.partyAddress[i]);
              break;
            }
          }
          if (results[idx].address.length === 0) {
            for (var j = 0; j < pIns.partyAddress.length; j++) {
              if (pIns.partyAddress[j].addressType.toLowerCase() == 'home') {
                results[idx].address.push(pIns.partyAddress[j]);
                break;
              }
            }
          }
        } else {
          results[idx].policies.push(value);
        }
        $.each(value.partyList, function(index, value) {
          if (
            $.grep(results[idx].additional, function(n) {
              return (
                n.firstName == value.firstName && n.lastName == value.lastName
              );
            }).length === 0 &&
            value.mdmid != pIns.mdmid
          )
            results[idx].additional.push(value);
        });
      } else {
        if (aaaie.debug) {
          // eslint-disable-next-line no-console
          console.log(
            'No primary insured found for policy: ' + value.policyNumber
          );
        }

        // Decrement total count facets in case of membership search and persons membership id
        // mentioned on the policy does not match with the search criterea
        data = aaaie.Search.decrementTotalCountFacets(value, data);
      }
      value.policyStatus = aaaie.Search.mapPolicyStatusLabels(
        value.policyStatus
      );
    });

    $.each(data.searchedQuotes, function(index, value) {
      // remove quotes from NETPOSITIVE
      if (value.sourceSystem === 'NETPOSITIVE') {
        // remove this quote from the counts
        data = aaaie.Search.decrementTotalCountFacets(value, data, true);
        return;
      }

      // ensure the quote has a primary insured
      var ins = $.grep(value.partyList, function(n) {
        return (
          $.grep(n.partyRoles, function(n) {
            return n.roleType.indexOf('INS') > -1;
          }).length > 0
        );
      });
      if (ins.length > 0) {
        var sortedLast =
          value.sortedFields['1'].length > 0
            ? value.sortedFields['1']
            : value.sortedFields['3'];
        var sortedFirst =
          value.sortedFields['2'].length > 0
            ? value.sortedFields['2']
            : value.sortedFields['4'];
        var sortedIns = $.grep(ins, function(n) {
          return n.lastName == sortedLast && n.firstName == sortedFirst;
        });
        if (!sortedIns || sortedIns.length === 0) {
          sortedIns = $.grep(ins, function(n) {
            return n.lastName == sortedLast;
          });
        }
        var pIns = sortedIns[0] || ins[0];
        //TODO make this a hash of more fields, or ideally use an assigned identifier when available ZA
        //Removed grouping for now ZA
        //var idx = pIns.firstName + pIns.lastName + pIns.dob;
        var idx = 'quote- ' + value.quoteNumber;
        if (!results[idx]) {
          results[idx] = {};
          results[idx].insured = pIns;
          results[idx].quotes = [aaaie.Search.cleanQuote(value)];
          results[idx].additional = [];
          results[idx].riskZip = aaaie.Search.getPartyAddressZipCode(
            pIns.partyAddress[0]
          );
          results[idx].address = [];
          for (var i = 0; i < pIns.partyAddress.length; i++) {
            if (pIns.partyAddress[i].addressType.toLowerCase() == 'home') {
              results[idx].address.push(pIns.partyAddress[i]);
            }
          }
        } else {
          results[idx].quotes.push(aaaie.Search.cleanQuote(value));
        }
        $.each(value.partyList, function(index, value) {
          if (
            $.grep(results[idx].additional, function(n) {
              return (
                n.firstName == value.firstName && n.lastName == value.lastName
              );
            }).length === 0 &&
            (value.firstName !== pIns.firstName ||
              value.lastName !== pIns.lastName)
          )
            results[idx].additional.push(value);
        });
      } else {
        if (aaaie.debug)
          // eslint-disable-next-line no-console
          console.log(
            'No primary insured found for quote: ' + value.quoteNumber
          );
      }
      value.quoteStatus = aaaie.Search.mapQuoteStatusLabels(value.quoteStatus);
    });

    aaaie.Search.sortResults(results);

    if (memNumID.data('data-isSecondSearch') && !memNum) {
      memNumID.data('data-isSecondSearch', '');
    }
    return results;
  },

  decrementTotalCountFacets: function(value, data, isQuote) {
    if (isQuote) {
      data.facets.totalCountByDocType.QUOTE -= 1;
      data.facets.totalCountByDocTypeCount -= 1;

      data.facets.totalCountByQuoteStatus[value.quoteStatus] -= 1;
      data.facets.totalCountByQuoteStatusCount -= 1;
    } else {
      data.facets.totalCountByDocType.POLICY -= 1;
      data.facets.totalCountByDocTypeCount -= 1;

      data.facets.totalCountByPolicyStatus[value.policyStatus] -= 1;
      data.facets.totalCountByPolicyStatusCount -= 1;
    }

    data.facets.totalCountByProdTypeCode[value.prodTypeCode] -= 1;
    data.facets.totalCountByProdTypeCodeCount -= 1;

    if (data.facets.totalCountByRiskState) {
      data.facets.totalCountByRiskState[value.riskState] -= 1;
      data.facets.totalCountByRiskStateCount -= 1;
    }

    return data;
  },

  sortResults: function(results) {
    var autoCodes = 'au';
    var homeCodes = 'ho,renters,dp';
    $.each(results, function(key, value) {
      if (value.policies && value.policies.length > 1) {
        //call multiple times to deep sort on multiple properties ZA
        value.policies.sort(sortPolicies);
        value.policies.sort(sortPolicies);
        value.policies.sort(sortPolicies);
      }
      if (value.quotes && value.quotes.length > 1) {
        //call multiple times to deep sort on multiple properties ZA
        value.quotes.sort(sortQuotes);
        value.quotes.sort(sortQuotes);
        value.quotes.sort(sortQuotes);
      }
    });

    function sortPolicies(a, b) {
      var aExpDate, bExpDate;
      aExpDate = Date.parse(a.termExpirationDate);
      bExpDate = Date.parse(b.termExpirationDate);
      if (
        a.policyStatus.toLowerCase() == 'active' &&
        b.policyStatus.toLowerCase() == 'inactive'
      )
        return -1;
      else if (
        a.policyStatus.toLowerCase() == 'inactive' &&
        b.policyStatus.toLowerCase() == 'active'
      )
        return 1;
      else if (
        autoCodes.indexOf(a.prodTypeCode.toLowerCase()) > -1 &&
        autoCodes.indexOf(b.prodTypeCode.toLowerCase()) == -1
      )
        return -1;
      else if (
        homeCodes.indexOf(a.prodTypeCode.toLowerCase()) > -1 &&
        homeCodes.indexOf(b.prodTypeCode.toLowerCase()) == -1
      )
        return 1;
      else if (
        Math.abs(Date.now() - aExpDate) < Math.abs(Date.now() - bExpDate)
      )
        return -1;
      else if (
        Math.abs(Date.now() - aExpDate) > Math.abs(Date.now() - bExpDate)
      )
        return 1;
      else if (a.policyNumber < b.policyNumber) return -1;
      else return 1;
    }

    function sortQuotes(a, b) {
      var aUpdDate, bUpdDate;
      aUpdDate = Date.parse(a.quoteUpdatedDt);
      bUpdDate = Date.parse(b.quoteUpdatedDt);
      if (
        autoCodes.indexOf(a.prodTypeCode.toLowerCase()) > -1 &&
        autoCodes.indexOf(b.prodTypeCode.toLowerCase()) == -1
      )
        return -1;
      else if (
        homeCodes.indexOf(a.prodTypeCode.toLowerCase()) > -1 &&
        homeCodes.indexOf(b.prodTypeCode.toLowerCase()) == -1
      )
        return 1;
      else if (
        Math.abs(Date.now() - aUpdDate) < Math.abs(Date.now() - bUpdDate)
      )
        return -1;
      else if (
        Math.abs(Date.now() - aUpdDate) > Math.abs(Date.now() - bUpdDate)
      )
        return 1;
      else if (a.quoteNumber < b.quoteNumber) return -1;
      else return 1;
    }
  },

  getParameterByName: function(name) {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('#' + name + '=([^&#]*)'),
      results = regex.exec(location.href),
      escapedValue = '';

    if (detectBrowser().indexOf('Firefox') === 0) {
      //Firefox
      escapedValue = results ? results[1].replace(/\+/g, ' ') : '';
    } else {
      //Chrome
      escapedValue = results ? escape(results[1].replace(/\+/g, ' ')) : '';
    }
    return !escapedValue ? '' : decodeURIComponent(escapedValue);
  },

  // For the second call in the membership search, build the new url and redirect to it.
  membershipRedirect: function(memObj, options) {
    //if(options.memNum){
    try {
      // AS : Set the membership empty in the second search.
      options.memNum = '';
      $('#memNum').data('data-isSecondSearch', true);

      options.firstName = memObj.firstName;
      options.lastName = memObj.lastName;
      if (memObj.partyAddress) {
        if (
          aaaie.$.isArray(memObj.partyAddress) &&
          memObj.partyAddress.length > 0
        ) {
          if (aaaie.Search.getPartyAddressZipCode(memObj.partyAddress[0])) {
            options.zipCode = memObj.partyAddress[0].zipCode.substring(0, 5);
          }
        } else if (aaaie.Search.getPartyAddressZipCode(memObj.partyAddress)) {
          options.zipCode = memObj.partyAddress.zipCode.substring(0, 5);
        }
      }

      // AS : Before changing the window location set the memship variable to search the response at a later point of time.
      var membershipNumber = aaaie.Search.getParameterByName("memNum");
      $('#memNum').data('memNum', membershipNumber);

      // AS : If this search result was the result of a membership search then make the search again with
      // the firstName , lastName and the zipCode being passed.
      window.location =
        'search#term=#firstName=' +
        options.firstName.replace(/ /g, '+') +
        '#lastName=' +
        options.lastName.replace(/ /g, '+') +
        '#policyNumber=' +
        '#quoteNumber=' +
        '#phoneNumber=' +
        '#zipCode=' +
        options.zipCode.replace(/ /g, '+') +
        '#memNum='+
        '#searchedMemNum=' + membershipNumber;
    } catch (e) {
      // Do nothing resume normal process.
    }
    // }
  },

  cleanQuote: function(quote) {
    if (quote.sourceSystem.toLowerCase() == 'netpositive')
      quote.sourceSystem = 'NETPOS';
    if (quote.sourceSystem.toLowerCase() == 'exigen')
      quote.sourceSystem = 'PAS';
    if (
      quote.sourceSystem.toLowerCase() == 'netpos' ||
      quote.sourceSystem.toLowerCase() == 'pas' ||
      quote.sourceSystem.toLowerCase() == 'qpas'
    )
      quote.openQuote = true;
    else quote.openQuote = false;

    if (
      window.wms_user_info.riskStateNationalCode !== '005' &&
      window.wms_user_info.agentRegion !== 'Eastern'
    ) {
      if (
        quote.sourceSystem == 'NETPOS' &&
        window.wms_user_info.legacyId === ''
      ) {
        quote.openQuote = false;
      }
    }

    if (
      isAgentQuoteVisible == 'true' &&
      (quote.prodTypeCode == 'PA' || quote.prodTypeCode == 'AU') &&
      (quote.sourceSystem.toLowerCase() == 'netpos' ||
        quote.sourceSystem.toLowerCase() == 'pas' ||
        quote.sourceSystem.toLowerCase() == 'qpas')
    )
      quote.newQuote = true;
    else quote.newQuote = false;

    if (quote.openQuote || quote.newQuote) quote.noQuoteDropdown = false;
    else quote.noQuoteDropdown = true;

    return quote;
  },

  //TODO need a cleanPolicies that determines enabled state of the view policy button based on A/C  ZA

  mapQuoteStatusLabels: function(fromSearchApi) {
    var map = {
      INCOMPLETE: 'Data Gathering',
      QUOTED: 'Prem Calculated'
    };
    return map[fromSearchApi] || fromSearchApi;
  },

  mapPolicyStatusLabels: function(fromSearchApi) {
    var label = 'Inactive';
    if (
      fromSearchApi === 'ACTIVE' ||
      fromSearchApi === 'PENDING' ||
      fromSearchApi === 'RENEWAL SECURED' ||
      fromSearchApi === 'RENEWAL LETTER SENT 83 DAYS FOR PUPSTATUS = C' ||
      fromSearchApi === 'PERMIT TO LAPSE AFTER EXP' ||
      fromSearchApi === 'CONVERTED'
    ) {
      label = 'Active';
    } else if (fromSearchApi === 'PROPOSED') {
      label = 'Proposed';
    }
    return label;
  },

  //lj: map product types
  mapProductTypeLabels: function(fromSearchApi) {
    var label = 'Auto';
    if (fromSearchApi === 'AU' || fromSearchApi === 'PA') {
      label = 'Auto';
    } else if (
      fromSearchApi === 'HO' ||
      fromSearchApi === 'RENTERS' ||
      fromSearchApi === 'DP'
    ) {
      label = 'Home';
    } else if (fromSearchApi === 'PU' || fromSearchApi === 'PUP') {
      label = 'PUP';
    }
    return label;
  },

  // TODO: Fix to show zeroes??
  getDisplayFacets: function(facets, oldDisplayFacets) {
    // console.log("getDisplayFacets, oldDisplayFacets: " + JSON.stringify(oldDisplayFacets));
    var displayFacets = oldDisplayFacets || {
      prodTypeCode: {},
      docType: {},
      policyStatus: {},
      quoteStatus: {},
      riskState: {},
      sortedStates: [],
      totalCount: 0
    };

    if (!oldDisplayFacets) {
      if (facets.totalCountByProdTypeCode) {
        displayFacets.prodTypeCode.Auto = {
          v: 0,
          fk: 'prodTypeCode',
          fv: 'Auto'
        };
        displayFacets.prodTypeCode.Home = {
          v: 0,
          fk: 'prodTypeCode',
          fv: 'Home'
        };
        displayFacets.prodTypeCode.PUP = {
          v: 0,
          fk: 'prodTypeCode',
          fv: 'PUP'
        };
        $.each(facets.totalCountByProdTypeCode, function(key, value) {
          var newKey = aaaie.Search.mapProductTypeLabels(key);
          // we want to add up all the "inactive" policies into a single value.
          if (displayFacets.prodTypeCode[newKey]) {
            displayFacets.prodTypeCode[newKey].v += value;
          } else {
            displayFacets.prodTypeCode[newKey] = {
              v: value,
              fk: 'prodTypeCode',
              fv: newKey.toLowerCase()
            };
          }
        });
      } else {
        displayFacets.prodTypeCode.Auto = {
          v: 0,
          fk: 'prodTypeCode',
          fv: 'Auto'
        };
        displayFacets.prodTypeCode.Home = {
          v: 0,
          fk: 'prodTypeCode',
          fv: 'Home'
        };
        displayFacets.prodTypeCode.PUP = {
          v: 0,
          fk: 'prodTypeCode',
          fv: 'PUP'
        };
      }
      if (facets.totalCountByDocType) {
        displayFacets.docType.Policies = {
          k: 'Policies',
          v: facets.totalCountByDocType.POLICY || 0,
          fk: 'docType',
          fv: 'policy'
        };
        displayFacets.docType.Quotes = {
          k: 'Quotes',
          v: facets.totalCountByDocType.QUOTE || 0,
          fk: 'docType',
          fv: 'quote'
        };
      } else {
        displayFacets.docType.Policies = {
          k: 'Policies',
          v: 0,
          fk: 'docType',
          fv: 'policy'
        };
        displayFacets.docType.Quotes = {
          k: 'Quotes',
          v: 0,
          fk: 'docType',
          fv: 'quote'
        };
      }
      if (facets.totalCountByPolicyStatus) {
        if (aaaie.Search.config.staticFacetLabel) {
          displayFacets.policyStatus.Active = {
            v: 0,
            fk: 'policyStatus',
            fv: 'active'
          };
          displayFacets.policyStatus.Inactive = {
            v: 0,
            fk: 'policyStatus',
            fv: 'inactive'
          };
          displayFacets.policyStatus.Proposed = {
            v: 0,
            fk: 'policyStatus',
            fv: 'proposed'
          };
        }
        $.each(facets.totalCountByPolicyStatus, function(key, value) {
          var newKey = aaaie.Search.mapPolicyStatusLabels(key);
          // we want to add up all the "inactive" policies into a single value.
          if (displayFacets.policyStatus[newKey]) {
            displayFacets.policyStatus[newKey].v += value;
          } else {
            displayFacets.policyStatus[newKey] = {
              v: value,
              fk: 'policyStatus',
              fv: newKey.toLowerCase()
            };
          }
        });
      } else {
        if (aaaie.Search.config.staticFacetLabel) {
          displayFacets.policyStatus.Active = {
            v: 0,
            fk: 'policyStatus',
            fv: 'active'
          };
          displayFacets.policyStatus.Inactive = {
            v: 0,
            fk: 'policyStatus',
            fv: 'inactive'
          };
          displayFacets.policyStatus.Proposed = {
            v: 0,
            fk: 'policyStatus',
            fv: 'proposed'
          };
        }
      }
      if (facets.totalCountByQuoteStatus) {
        if (aaaie.Search.config.staticFacetLabel) {
          displayFacets.quoteStatus.INCOMPLETE = {
            v: 0,
            fk: 'quoteStatus',
            fv: 'incomplete',
            label: aaaie.Search.mapQuoteStatusLabels('INCOMPLETE')
          };
          displayFacets.quoteStatus.QUOTED = {
            v: 0,
            fk: 'quoteStatus',
            fv: 'quoted',
            label: aaaie.Search.mapQuoteStatusLabels('QUOTED')
          };
        }
        $.each(facets.totalCountByQuoteStatus, function(key, value) {
          displayFacets.quoteStatus[key] = {
            v: value,
            fk: 'quoteStatus',
            fv: key.toLowerCase(),
            label: aaaie.Search.mapQuoteStatusLabels(key)
          };
        });
      } else {
        if (aaaie.Search.config.staticFacetLabel) {
          displayFacets.quoteStatus.INCOMPLETE = {
            v: 0,
            fk: 'quoteStatus',
            fv: 'incomplete',
            label: aaaie.Search.mapQuoteStatusLabels('INCOMPLETE')
          };
          displayFacets.quoteStatus.QUOTED = {
            v: 0,
            fk: 'quoteStatus',
            fv: 'quoted',
            label: aaaie.Search.mapQuoteStatusLabels('QUOTED')
          };
        }
      }
    }
    // states are different... they may appear/disapper after filtering.
    // we want them to persist.
    if (facets.totalCountByRiskState) {
      $.each(displayFacets.riskState, function(key) {
        displayFacets.riskState[key].v = 0;
      });
      $.each(facets.totalCountByRiskState, function(key, value) {
        displayFacets.riskState[key] = {
          v: value,
          fk: 'riskState',
          fv: key.toLowerCase(),
          label: key.toUpperCase()
        };
      });

      // This sorting should ideally be done by the Search API.  We shouldn't be doing this here.
      displayFacets.sortedStates = [];
      for (var k in displayFacets.riskState) {
        displayFacets.sortedStates.push(displayFacets.riskState[k]);
      }
      displayFacets.sortedStates.sort(function(a, b) {
        return a.label.localeCompare(b.label);
      });
    }
    displayFacets.totalCount = facets.totalCountByDocTypeCount || 0;

    // console.log("new displayFacets: " + JSON.stringify(displayFacets));
    return displayFacets;
  },

  getBaseUrl: function() {
    return window.location.protocol + '//' + window.location.host;
  },

  buildSearchHeader: function(options) {
    var _this = aaaie.Search;
    var header = {
      //  ask API team to change so we don't need to pass in "null" strings.
      channelType: _this.getChannelType(),
      agencyCode: 'null',
      requestType: 'null',
      agentid: options.wmsUserInfo ? options.wmsUserInfo.agentId : 'null'
    };

    if (_this.config.portal === 'iPortal') {
      header.requestType = 'IP';
    }

    if (_this.getParameterByName('memNum')) {
      header.channelType = 'DSU';
    }

    if (options.firstDisplayResult || options.firstDisplayResult === 0) {
      header.from = options.firstDisplayResult + '';
    }
    if (options.maxResults) {
      header.size = options.maxResults + '';
      if (header.size.toLowerCase() === 'all') {
        header.size = '200';
      }
    }
    return header;
  },

  // options should include filters, searchParam, firstDisplayResult, maxResults
  buildSearchRequest: function(options) {
    // we only display "inactive", but really we want to search all the inactive statuses.
    if (
      options.filters &&
      options.filters.policyStatus &&
      options.filters.policyStatus.indexOf('inactive') > -1
    ) {
      options.filters.policyStatus.splice(
        0,
        0,
        'CANCELLED',
        'LAPSED',
        'DECLINED',
        'NON RENEWED'
      );
    }
    // we consider Pending to be Active for some reason.
    if (
      options.filters &&
      options.filters.policyStatus &&
      options.filters.policyStatus.indexOf('active') > -1
    ) {
      options.filters.policyStatus.splice(
        0,
        0,
        'PENDING',
        'RENEWAL SECURED',
        'RENEWAL LETTER SENT 83 DAYS FOR PUPSTATUS = C',
        'PERMIT TO LAPSE AFTER EXP',
        'CONVERTED'
      );
    }
    // we only display "Auto", but really we want to search all the auto product types;
    if (
      options.filters &&
      options.filters.prodTypeCode &&
      options.filters.prodTypeCode.indexOf('Auto') > -1
    ) {
      options.filters.prodTypeCode.splice(0, 0, 'AU', 'PA');
    }
    // we only display "Home", but really we want to search all the home product types;
    if (
      options.filters &&
      options.filters.prodTypeCode &&
      options.filters.prodTypeCode.indexOf('Home') > -1
    ) {
      options.filters.prodTypeCode.splice(0, 0, 'HO', 'RENTERS', 'DP');
    }
    if (
      options.filters &&
      options.filters.prodTypeCode &&
      options.filters.prodTypeCode.indexOf('PUP') > -1
    ) {
      options.filters.prodTypeCode.splice(0, 0, 'PU');
    }
    var twoYearsAgo, request;
    twoYearsAgo = new Date();
    twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2);
    request = {
      searchRequest: {
        header: aaaie.Search.buildSearchHeader(options),
        sort: [
          {
            lastName: 'asc',
            firstName: 'asc'
          }
        ]
      }
    };
    requestParam = {
      search: options.searchParam || '',
      // we always want to filter out other types, like PUP & specialty:
      prodTypeCode: ['AU', 'HO', 'RENTERS', 'DP', 'PA', 'PU', 'PUP'],
      // these are the only two quote statuses we care about. exclude the rest.
      quoteStatus: ['QUOTED', 'INCOMPLETE'],
      // search all policy statuses by default.
      policyStatus: [
        'CANCELLED',
        'LAPSED',
        'DECLINED',
        'NON RENEWED',
        'PENDING',
        'ACTIVE',
        'ACTIVE PENDING COMPLETION',
        'EXPIRED ISSUED',
        'INACTIVE',
        'RENEWAL SECURED',
        'RENEWAL LETTER SENT 83 DAYS FOR PUPSTATUS = C',
        'PERMIT TO LAPSE AFTER EXP',
        'PERMIT TO LAPSE NO CONTACT',
        'CONVERTED',
        'PROPOSED',
        'CANC AFTER EXP DT UPLOAD',
        'LEAD TIME REN LETTER',
        'IOL REP SENT 62 DAYS'
      ],
      quoteUpdatedFromDate: aaaie.Date.format(twoYearsAgo, 'YYYY/MM/DD')
    };
    if (options.firstName) {
      requestParam.firstName = options.firstName;
    }
    if (options.lastName) {
      requestParam.lastName = options.lastName;
    }
    if (options.policyNumber) {
      requestParam.policyNumber = options.policyNumber;
    }
    if (options.quoteNumber) {
      requestParam.quoteNumber = options.quoteNumber;
    }
    if (options.phoneNumber) {
      requestParam.phoneNumber = {
        value: options.phoneNumber || '',
        type: [
          'homePhoneNumber',
          'workPhoneNumber',
          'cellularPhoneNumber',
          'primaryPhoneNumber',
          'alternatePhoneNumber'
        ]
      };
    }
    if (options.zipCode) {
      requestParam.zipCode = {
        value: options.zipCode || '',
        type: ['risk', 'billing', 'mailing', 'home']
      };
    }
    if (options.memNum) {
      requestParam.policyNumber = options.memNum;
      requestParam.prodTypeCode = 'mshp';
    } else {
      requestParam.policyTermExpirationFromDate = aaaie.Date.format(
        twoYearsAgo,
        'YYYY/MM/DD'
      );
    }
    request.searchRequest.requestParam = requestParam;

    $.extend(requestParam, options.filters);
    // current search API does not accept an array of values for the "docType".
    //  hopefully they will in the future, and we can delete this hacky code.
    if (
      requestParam.docType &&
      requestParam.docType.length === 1 &&
      requestParam.docType[0] === 'quote'
    ) {
      delete requestParam.policyStatus;
      requestParam.docType = 'quote';
    } else if (
      requestParam.docType &&
      requestParam.docType.length === 1 &&
      requestParam.docType[0] === 'policy'
    ) {
      delete requestParam.quoteStatus;
      requestParam.docType = 'policy';
    } else {
      // user did not choose "quotes" or "policies" or they chose both.
      delete requestParam.docType;
    }
    return request;
  },

  renderTypeAhead: function(typeAheadInput, options) {
    var config = aaaie.Search.config;
    aaaie.$.extend(true, config, options);

    var typeAheadButton = aaaie.$('.typeahead-button')[0],
      typeAheadList = $(typeAheadInput).next('.typeahead-list');
    selectedIndex = 0;
    if (typeAheadList.length === 0) {
      typeAheadList = aaaie.$(
        '<ul id="drop2" class="typeahead-list hide"></ul>'
      );
      $(typeAheadInput).after(typeAheadList);
    }

    $(typeAheadButton).on('click', submitRequest);

    $(typeAheadInput).on('keyup', function(event) {
      var param = $.trim($(typeAheadInput).val());
      var keysToIgnore = [
        9,
        16,
        17,
        18,
        19,
        20,
        27,
        33,
        34,
        35,
        36,
        37,
        39,
        44,
        45,
        112,
        113,
        114,
        115,
        116,
        117,
        118,
        119,
        120,
        121,
        122,
        123,
        144,
        145
      ];
      if ($.inArray(event.which, keysToIgnore) > -1) {
        return;
      }
      if (event.which == 13 && param.length >= aaaie.Search.config.minLength) {
        aaaie.Analytics.defaultLowLevel(
          'Search-Textfield',
          'Search-ReturnKey-Textfield'
        )(event, {});
        submitRequest();
        return;
      }
      // 40 = down, 38 = up
      if (event.which === 40) {
        if (aaaie.Search.config.typeAheadLimit == selectedIndex) {
          selectedIndex = 0;
        }
        selectedIndex++;
        focusOnItem(selectedIndex);
        return;
      }
      if (event.which === 38) {
        selectedIndex--;
        if (selectedIndex === 0) {
          selectedIndex = aaaie.Search.config.typeAheadLimit;
        }
        focusOnItem(selectedIndex);
        return;
      }
      if (param.length >= aaaie.Search.config.minLength) {
        aaaie.Search.typeAhead({
          searchParam: param,
          successHandler: handleTypeAhead,
          wmsUserInfo: window.wms_user_info // ugly global variable
        });
        if (aaaie.Search.config.portal === 'iPortal') {
          if (
            typeAheadList.html() !== '' &&
            $(document.activeElement)[0] === event.target
          ) {
            aaaie.Analytics.defaultLowLevel(
              'Search-Dropdown',
              'Search-AutoCompleteResults-Dropdown'
            )(event, {});
            $('#search-pointer').removeClass('hide');
            typeAheadList.removeClass('hide');
          }
        } else {
          typeAheadList.removeClass('hide');
        }
      } else {
        typeAheadList.addClass('hide');
        if (aaaie.Search.config.portal === 'iPortal') {
          $('#search-pointer').addClass('hide');
          typeAheadList.html('');
        }
      }
    });

    if (aaaie.Search.config.portal !== 'iPortal') {
      var typeahead_container = $('.typeahead-container');
      typeahead_container.on('mouseenter', function() {
        if (
          $.trim($(typeAheadInput).val()).length >=
          aaaie.Search.config.minLength
        ) {
          typeAheadList.removeClass('hide');
        }
      });
      typeahead_container.on('mouseleave', function() {
        if (
          $.trim($(typeAheadInput).val()).length >=
          aaaie.Search.config.minLength
        ) {
          typeAheadList.addClass('hide');
        }
      });
    }

    function focusOnItem(index) {
      var li = typeAheadList.find('li:nth-child(' + index + ')');
      if (li.length !== 0) {
        typeAheadList
          .find('.typeahead-item-selected')
          .removeClass('typeahead-item-selected');
        li.addClass('typeahead-item-selected');
        $(typeAheadInput).val(li.text());
      }
    }

    function formatTypeAheadItem(item) {
      var param = $(typeAheadInput).val(),
        len = param ? param.length : 0,
        lowerCaseItem = item.toLowerCase(),
        index = param ? lowerCaseItem.indexOf(param.toLowerCase()) : -1,
        output = item;

      if (index >= 0) {
        output = item.substr(0, index) + '<span class="typeahead-bold">';
        output += item.substr(index, len) + '</span>';
        output += item.substr(index + len);
      }
      return output;
    }

    function handleTypeAhead(data) {
      if (data.responseJson && data.responseJson.result) {
        var result = data.responseJson.result;
        typeAheadList.empty();
        selectedIndex = 0;
        for (var i = 0; i < result.length; i++) {
          // TODO: ugly code, redrawing the dom repeatedly in a loop.  what happened to my documentFragment code?  -gstroup
          if (i < aaaie.Search.config.typeAheadLimit)
            $(
              '<li><a href="#" data-analytics-label="Search-Click-Link" data-analytics-action="AutoCompleteResults">' +
                formatTypeAheadItem(result[i]) +
                '</a></li>'
            )
              .on('click', handleTypeAheadClick)
              .appendTo(typeAheadList);
          else break;
        }
        if (typeAheadList.children().length === 0) {
          typeAheadList.addClass('hide');
          if (aaaie.Search.config.portal === 'iPortal') {
            $('#search-pointer').addClass('hide');
          }
        } else if (aaaie.Search.config.portal === 'iPortal') {
          aaaie.Analytics.defaultLowLevel(
            'Search-Dropdown',
            'Search-AutoCompleteResults-Dropdown'
          )(event, {});
          $('#search-pointer').removeClass('hide');
          typeAheadList.removeClass('hide');
        }
      }
    }

    function handleTypeAheadClick(evt) {
      var $target = $(evt.target);
      var searchParam = $target.text();
      if (!$target.is('li')) searchParam = $target.parent().text();
      if (aaaie.debug) {
        // eslint-disable-next-line no-console
        console.debug(searchParam);
      }

      //for iPortal make selected first name and last name Title case
      if (aaaie.Search.config.portal === 'iPortal') {
        var spaceIndex = searchParam.indexOf(' ');
        if (!/\d/.test(searchParam) && spaceIndex > 0) {
          var fName = searchParam.slice(0, spaceIndex);
          var lName = searchParam.slice(spaceIndex + 1);
          searchParam =
            fName.charAt(0).toUpperCase() +
            fName.slice(1).toLowerCase() +
            ' ' +
            lName.charAt(0).toUpperCase() +
            lName.slice(1).toLowerCase();
        }
      }

      $(typeAheadInput).val(searchParam);
      submitRequest();
    }

    // TODO: Should we remove this tight coupling to search?
    // YES ZA
    function submitRequest() {
      if (
        !$(typeAheadInput).val() ||
        $.trim($(typeAheadInput).val()).length < aaaie.Search.config.minLength
      ) {
        return;
      }
      if (config.searchResultsHandler) {
        var options = {};
        typeAheadList.addClass('hide');
        options.searchParam = $(typeAheadInput).val().trim();
        options.successHandler = config.searchResultsHandler;
        options.errorHandler = config.searchResultsErrorHandler;
        options.firstDisplayResult = 0;
        options.wmsUserInfo = window.wms_user_info;
        options.maxResults =
          aaaie.Cookies.get('aaaie.searchResultsPerPage') || 15;
        if ($(typeAheadInput).val() == aaaie.Cookies.get('aaaie.searchTerm')) {
          aaaie.Search.config.sameSearchParam = true;
        }
        // added for stickysettings
        var filtersVal = aaaie.$.extend(
          true,
          {},
          aaaie.Cookies.get('aaaie.searchStickyFilters')
        );
        if (!$.isEmptyObject(filtersVal)) {
          aaaie.Search.config.displayStickyFilters = true;
          // first call with out filters to retreive whole results and display it in facets
          // second call with filters and display those results in results page.(not updating the facets)
          aaaie.Search.simpleSearch(options);
          aaaie.Search.simpleSearch(options, filtersVal);
        } else {
          aaaie.Search.simpleSearch(options);
        }
      } else if (config.redirectHandler) {
        $('#search-pointer').addClass('hide');
        typeAheadList.addClass('hide');
        config.redirectHandler();
      } else if (aaaie.debug) {
        // eslint-disable-next-line no-console
        console.debug('ERROR - Search request handler not provided.');
      }
    }
  },

  simpleSearch: function(options, filters) {
    var config = aaaie.$.extend(true, {}, aaaie.Search.config, options);

    options.filters = filters || {};

    if (!config.searchInProgress) {
      config.searchInProgress = true;
      $('body').toggleClass('searchInProgress');
      aaaie.Processindicator.setTimer($('.loading-indicator'), false);
      $.ajax({
        cache: false,
        url: aaaie.Search.getBaseUrl() + config.searchUrl,
        crossDomain: false,
        contentType: 'application/json',
        dataType: 'json',
        type: 'POST',
        data: JSON.stringify(aaaie.Search.buildSearchRequest(options)),
        success: function(response) {
          var formattedResponse = {};
          if (response.responseJson && response.responseJson.searchedPolicies) {
            // AS : Passing the memNum in the formatResults to check if we need to make a second search call.
            formattedResponse.results = aaaie.Search.formatResults(
              response.responseJson,
              options,
              filters
            );

            // AS : If formatted response has a empty object then we don't have to show anything in the results page.
            if ($.isEmptyObject(formattedResponse.results)) {
              formattedResponse.displayFacets = '';
            }
          }
          if (response.responseJson && response.responseJson.facets) {
            formattedResponse.displayFacets = aaaie.Search.getDisplayFacets(
              response.responseJson.facets,
              config.oldDisplayFacets
            );
          }
          formattedResponse.lastSearchParam = config.searchParam;
          // console.log("success: " + JSON.stringify(response));
          config.searchInProgress = false;
          $('body').toggleClass('searchInProgress');
          aaaie.Processindicator.stopSpinner($('.loading-indicator'));
          if (config.successHandler) {
            config.successHandler(formattedResponse);
          }
        },
        error: function(response) {
          config.searchInProgress = false;
          $('body').toggleClass('searchInProgress');
          aaaie.Processindicator.stopSpinner($('.loading-indicator'));
          if (aaaie.debug)
            // eslint-disable-next-line no-console
            console.debug('Error! ' + JSON.stringify(response, null, 2));
          if (config.errorHandler) {
            config.errorHandler();
          }
        }
      });
    }
  },

  getPartyAddressZipCode: function(address) {
    return address ? address.zipCode : '';
  },

  typeAhead: function(options) {
    // var config = aaaie.Search.config;
    var config = aaaie.$.extend(true, {}, aaaie.Search.config, options);

    $.ajax({
      cache: false,
      url: aaaie.Search.getBaseUrl() + config.autoCompleteUrl,
      crossDomain: false,
      contentType: 'application/json',
      dataType: 'json',
      type: 'POST',
      data: JSON.stringify(aaaie.Search.buildSearchRequest(options)),
      success: function(response) {
        //console.log("success: " + JSON.stringify(response));
        // window.alert("success: " + JSON.stringify(response));
        if (config.successHandler) {
          config.successHandler(response);
        }
      },
      error: function(response) {
        // eslint-disable-next-line no-console
        if (aaaie.debug) console.debug('Error! ' + JSON.stringify(response));
      }
    });
  },

  getChannelType: function() {
    var channelType = 'NDSU';
    if (
      wms_user_info &&
      wms_user_info.channelType &&
      wms_user_info.channelType.toLowerCase() === 'direct sales unit'
    ) {
      channelType = 'DSU';
    }
    return channelType;
  }
};
aaaie.$(document).ready(function() {
  'use strict';
  if (aaaie.Search.config.portal !== 'iPortal') {
    aaaie.$('[data-aaaie-widget="typeahead"]').each(function() {
      aaaie.Search.renderTypeAhead(this, aaaie.Search.config);
    });
  }
});
