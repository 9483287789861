aaaie.TestDecorators = {
  hold_aaaie_ready: function() {
    aaaie.$.holdReady(true);
  },
  run_aaaie_ready: function() {
    aaaie.$.holdReady(false);
  },
  withJasmine: {
    hasJasmine: function() {
      if (!jasmine) {
        throw 'Jasmine Testing Framework Required';
      }
    },
    spyDecorator: function(aaaieObject, methodName, fakeFunc) {
      if (!aaaieObject) {
        throw 'aaaieObject not found';
      }
      aaaie.TestDecorators.withJasmine.hasJasmine();
      if (!fakeFunc) {
        fakeFunc = function() {};
      }
      spyOn(aaaieObject, methodName).and.callFake(fakeFunc);
    },
    decorateElementRegisterWithSpy: function(fakeFunc) {
      aaaie.TestDecorators.hold_aaaie_ready();
      aaaie.TestDecorators.withJasmine.hasJasmine();
      beforeAll(function() {
        aaaie.TestDecorators.withJasmine.spyDecorator(
          aaaie.Elements,
          'register',
          fakeFunc
        );
        aaaie.TestDecorators.run_aaaie_ready();
      });
    }
  }
};
