/**
 * Implementation of process Indicator
 *
 * @type {Object}
 */
aaaie.Processindicator = {
  timerEvent: '',
  timerFlag: 1,
  spinner: new Spinner().spin(),
  target: '',
  opts: {
    lines: 11, // The number of lines to draw
    length: 6, // The length of each line
    width: 3, // The line thickness
    radius: 7, // The radius of the inner circle
    scale: 1, // Scales overall size of the spinner
    corners: 1, // Corner roundness (0..1)
    color: '#e6e6e6', // #rgb or #rrggbb or array of colors
    opacity: 0.25, // Opacity of the lines
    rotate: 0, // The rotation offset
    direction: 1, // 1: clockwise, -1: counterclockwise
    speed: 1, // Rounds per second
    trail: 60, // Afterglow percentage
    fps: 20, // Frames per second when using setTimeout() as a fallback for CSS
    zIndex: 2e9, // The z-index (defaults to 2000000000)
    className: 'spinner', // The CSS class to assign to the spinner
    top: '50%', // Top position relative to parent
    left: '50%', // Left position relative to parent
    shadow: false, // Whether to render a shadow
    hwaccel: false, // Whether to use hardware acceleration
    position: 'absolute' // Element positioning
  },

  start: function(target) {
    target.find('.spinner').remove();
    if (target.attr('timerFlag') === '2') {
      var spinner = new Spinner(aaaie.Processindicator.opts).spin();
      target.append(spinner.el);
    }
  },

  startWithOptions: function(target, opts) {
    target.find('.spinner').remove();
    if (target.attr('timerFlag') === '2') {
      var spinner = new Spinner(opts).spin();
      target.append(spinner.el);
    }
  },

  stop: function(target) {
    target.attr('timerFlag', 1);
    aaaie.Processindicator.timerFlag = 1;
    target.find('.spinner').remove();
    target.parent().parent().parent().parent().remove();
    //aaaie.Processindicator.spinner.stop();
  },

  stopSpinner: function(target) {
    if (target.find('.spinner').length > 0) {
      target.attr('timerFlag', 1);
      aaaie.Processindicator.timerFlag = 1;
      target.find('.spinner').remove();
    }
  },

  // For default style Process Indicator
  setTimer: function(target, elementType) {
    target.attr('timerFlag', 2);
    if (elementType === 'button') {
      aaaie.Processindicator.opts.left = '90%';
      aaaie.Processindicator.timerEvent = setTimeout(function() {
        aaaie.Processindicator.start(target);
      }, 2500);
    } else {
      aaaie.Processindicator.start(target);
    }
  },

  // For CUSTOM style Process Indicator
  setTimerWithOptions: function(target, opts, elementType) {
    target.attr('timerFlag', 2);
    if (elementType === 'button') {
      aaaie.Processindicator.timerEvent = setTimeout(function() {
        aaaie.Processindicator.startWithOptions(target, opts);
      }, 2500);
    } else {
      aaaie.Processindicator.startWithOptions(target, opts);
    }
  }
};
