aaaie.ConversionMessage = {
  config: {
    elementId: '',
    cssClass: 'conversion-msg',
    polOld: '',
    polNew: '',
    parentContainer: '',
    id: null
  },
  getPolicyType: function(policyType) {
    if (policyType === 'PA') {
      policyType = 'Auto';
    } else if (policyType === 'HO') {
      policyType = 'Property';
    } else {
      policyType = '';
    }
    return policyType;
  },

  shortMessage: function(policyType, oldPolicy, newPolicy) {
    return (
      'Your ' +
      policyType +
      ' policy number ' +
      oldPolicy +
      ' is changing to ' +
      newPolicy +
      '.'
    );
  },

  longMessage: function(policyType, oldPolicy, newPolicy, termDate) {
    return (
      'We&#39;re updating to a new insurance system. Although this change affects your policy number, your coverage will remain the same. ' +
      policyType +
      ' policy ' +
      oldPolicy +
      ' is changing to ' +
      newPolicy +
      ' on your renewal date of ' +
      termDate +
      '.'
    );
  },

  getTwoColumnStructure: function(msg) {
    return (
      "<div class='ineligible-block-conv-msg'><span class='conv-msg-line-height'>" +
      msg +
      "</span><span class='table-wrap'><div class='table-shim'></div><div class='icon-small-blue-question-conv-msg conv-msg-icon-right'></div></span></div>"
    );
  },

  renderConversionMessage: function(elem) {
    var _this = aaaie.ConversionMessage;
    var style = _this.config.cssClass;
    var convMsgInnerMap = {};

    $(elem + ' [data-aaaie-widget="conversionMessage"]').each(function() {
      var $this = $(this);
      var id = _this.config.id++;
      var data = JSON.parse($this.attr('data-widget-options'));
      var pt = _this.getPolicyType(data.policyType);

      if (data.style !== undefined) {
        style = data.style;
      }

      $this.append(
        "<div id='convMsgInner" +
          id +
          "' class='" +
          style +
          "' data-div-index='" +
          id +
          "'><div class='shortMsg'>" +
          _this.getTwoColumnStructure(
            _this.shortMessage(pt, data.newPolicy, data.oldPolicy)
          ) +
          "</div><div class='longMsg'>" +
          _this.getTwoColumnStructure(
            aaaie.ConversionMessage.longMessage(
              pt,
              data.newPolicy,
              data.oldPolicy,
              data.termDate
            )
          ) +
          '</div></div>'
      );

      var $longMsg = $this.find('.longMsg');
      var $shortMsg = $this.find('.shortMsg');

      convMsgInnerMap['convMsgInner' + id] = {
        longMsg: $longMsg,
        shortMsg: $shortMsg
      };

      $longMsg.css({ display: 'none' });
      $shortMsg.addClass('visible');
      $this.children().animate({ height: $this.height() }, 1);
    });

    $(elem + ' [data-aaaie-widget="conversionMessage"]')
      .off()
      .on('click', function(e) {
        var $convMsgInner = $(this).find('[id^=convMsgInner]');
        var $visible = $convMsgInner.find('.visible');
        var $longMsg = convMsgInnerMap[$convMsgInner.attr('id')].longMsg;
        var $shortMsg = convMsgInnerMap[$convMsgInner.attr('id')].shortMsg;

        $visible.hasClass('shortMsg')
          ? toggleAnimation($longMsg)
          : toggleAnimation($shortMsg);

        function toggleAnimation($elem) {
          $visible.css({ display: 'none' }).fadeOut(200).removeClass('visible');
          $elem.css({ display: 'block' }).fadeIn(300).addClass('visible');
          $convMsgInner.animate({ height: $elem.height() + 30 }, 200);
        }

        e.stopPropagation();
      });
  },

  clickme: function(parentId) {
    var h;
    var msg;

    if (
      $(parentId).find('#convMsgInner').text().trim() ==
      aaaie.Elements.policiesCard.conversionMessageShort
    ) {
      h = $(parentId).find('#convMsgLong').height();
      msg = aaaie.Elements.policiesCard.conversionMessageLong;
    } else {
      h = $(parentId).find('#convMsgShort').height();
      msg = aaaie.Elements.policiesCard.conversionMessageShort;
    }

    $(parentId)
      .find('#convMsgInner')
      .animate({ height: h + 30 }, 1000);
    $(parentId).find('#convMsgInner').text(msg);
  }
};
