/**
 * Root object of the JCL
 */
if ('undefined' === typeof aaaie) {
  aaaie = {};
}

/**
 * Version of the JCL
 *
 * @type {String}
 */
aaaie.VERSION = '1.5.42';

/**
 * Date when this version was compiled.
 *
 * @type {String}
 */
aaaie.BUILD_DATE = 'Tue, 27 Sep 2022 16:20:46 GMT';

/**
 * Flag representing whether the debug features are on.
 *
 * @type {Boolean}
 */
aaaie.debug = false;

/**
 * Global configurations for the JCL.
 *
 * @type {[type]}
 */
aaaie.config = aaaie.config || { persistLogging: true };

/**
 * The JCL's own version of jQuery.
 */
// aaaie.$ = jQuery.noConflict(true);
//below for b2b for now ZA
aaaie.$ = jQuery;
