aaaie.Pdf = {
  config: {
    workerSrc: 'pdf.worker.js'
  },
  count: 0,

  /**
   * Creates a thumbnail image of a PDF and inserts the image into the specified element
   *
   * @param  {Object} element DOM element that will contain the thumbnail image
   * @param  {String} path    path to PDF file
   * @param  {int} page     page number of PDF to use for thumbnail
   * @param  {int} widthOffset    offset from right side of PDF for thumbnail - to create thumbnail of partial pages
   * @param  {int} heightOffset    offset from bottom of PDF for thumbnail - to create thumbnail of partial pages
   */
  getThumbnail: function(element, path, page, widthOffset, heightOffset) {
    page = page | 1;
    widthOffset = widthOffset | 0;
    heightOffset = heightOffset | 0;
    PDFJS.getDocument(path).then(function(pdf) {
      pdf.getPage(page).then(function(page) {
        var viewport = page.getViewport(1.5);
        aaaie.Pdf.count++;
        aaaie
          .$('body')
          .append(
            '<canvas id="aaaie-pdf-box-' +
              aaaie.Pdf.count +
              '" style="display:none;"></canvas>'
          );
        var canvas = document.getElementById(
          'aaaie-pdf-box-' + aaaie.Pdf.count
        );
        var ctx = canvas.getContext('2d');
        canvas.height = viewport.height - heightOffset;
        canvas.width = viewport.width - widthOffset;
        var renderContext = {
          canvasContext: ctx,
          viewport: viewport
        };
        page.render(renderContext).then(function() {
          //set to draw behind current content
          ctx.globalCompositeOperation = 'destination-over';
          //set background color
          ctx.fillStyle = '#ffffff';
          //draw background / rect on entire canvas
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          var img = canvas.toDataURL();
          aaaie.$(element).html('<img src="' + img + '" />');
        });
      });
    });
  }
};

aaaie.$(document).ready(function() {
  PDFJS.workerSrc = aaaie.Pdf.config.workerSrc;
});
