/**
 * Created by pairuser on 2/4/16.
 */
aaaie.Tooltip = function($customElement) {
  $customElement.find('[data-aaaie-tooltip]').each(function() {
    var $element = aaaie.$(this);
    var $parent = $element.parent();

    $parent.find('.tooltip-box').remove();
    $parent.prepend(
      '<div class="tooltip-box"><span class="tooltip-help' +
        ($element.hasClass('[top]') ? ' top ' : '') +
        ($element.hasClass('[white]') ? 'white' : '') +
        '">' +
        $element.data('aaaie-tooltip') +
        '</span></div>'
    );

    var $tooltip = $parent.find('.tooltip-box');
    if ($element.data('tooltip-right')) {
      $tooltip.css('right', $element.data('tooltip-right'));
    }
    if ($element.data('tooltip-top')) {
      $tooltip.css('top', $element.data('tooltip-top'));
    }
    $tooltip.hide();
    $element.parent().hover(
      function() {
        if (!$tooltip.hasClass('disabled')) {
          $tooltip.show();
        }
      },
      function() {
        $tooltip.hide();
      }
    );
  });
};
