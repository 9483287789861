/**
 * Analytics implementation for the JCL. Most of the features are
 * based off of the Google Analytics suite of APIs but delivered
 * in a declarative manner. Imperative implementation is based on
 * custom jQuery event handling.
 *
 * @type {Object}
 */
aaaie.Analytics = {
  config: {
    /**
     * The global Google Analytics function object to use to pass events to.
     * Configured within the Google Tag Manager Container Tags page for the
     * Digital Services Global Tags 'Digital Services Global Analytics' tag
     * as well as within the script snippet within each template.
     */
    globalGA: 'aaaie_ga',
    /**
     * The global Google Tag Manager data layer object to use to pass
     * data to. Configured within the GTM script snippet within each template.
     */
    globalDataLayer: 'aaaie_dataLayer',
    globalGTMTagId: 'GTM-WH3CLG',
    actionAttribute: 'data-analytics-action',
    labelAttribute: 'data-analytics-label',
    sendValueAttribute: 'data-analytics-send',
    customEventName: 'aaaie.analytics.event',
    ignoreElement: 'aaaie.analytics.ignore',
    documentUrl: 'data-analytics-documentURL',
    documentTitle: 'data-analytics-documentTitle',
    pageTitle: '',
    pagePath: ''
  },

  /**
   * Function for loading GTM tag - from the GTM tag settings.
   * GA account #'s are loaded via macro.
   * The macros reference the trackingId function below.
   */
  loadGTM: function(l, i) {
    var w, d, s;
    w = window;
    d = document;
    s = 'script';
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = '//www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  },

  /**
   * Returns information about the current URL via the
   * JCL URL API.
   */
  currentURL: function() {
    return aaaie.Url.parse(document.location);
  },

  /**
   * Returns the current Host/domain information for the current
   * URL.
   */
  currentHost: function() {
    return aaaie.Analytics.currentURL().attr('host');
  },

  /**
   * Hashes information
   */
  hashInfo: function(infoStr) {
    var hash = 0;
    var charCode;
    if (infoStr === undefined || infoStr === null || infoStr.length === 0)
      return hash;
    for (var i = 0; i < infoStr.length; i++) {
      charCode = infoStr.charCodeAt(i);
      hash = (hash << 5) - hash + charCode;
      // Convert to 32bit integer
      hash = hash & hash;
    }
    return hash;
  },

  /**
   * Provides predicate function to help determine what the
   * current context is (page - B2B, B2C, AgencyPort & user - Agent, consumer etc).
   */
  contexts: {
    page: {
      isMPL: function() {
        return (
          aaaie.Analytics.currentHost().toLowerCase().indexOf('mypolicy') > -1
        );
      },
      isB2C: function() {
        var directory = aaaie.Analytics.currentURL()
          .attr('directory')
          .toLowerCase();
        //TODO fix this ZA
        return (
          directory.indexOf('selfservice') > -1 || directory.indexOf('b2c') > -1
        );
      },
      isAgentPortal: function() {
        if (typeof wms_user_info !== 'undefined') {
          var directory = aaaie.Analytics.currentURL()
            .attr('directory')
            .toLowerCase();
          return directory.indexOf('/myportal/') > -1;
        } else return false;
      },
      isIPortal: function() {
        if (typeof wms_user_info !== 'undefined') {
          return (
            aaaie.Analytics.currentHost().toLowerCase().indexOf('iportal') > -1
          );
        } else return false;
      },
      isB2E: function() {
        return (
          aaaie.Analytics.currentHost().toLowerCase().indexOf('passport') > -1
        );
      },
      isCAO: function() {
        var directory = aaaie.Analytics.currentURL()
          .attr('directory')
          .toLowerCase();
        return directory.indexOf('/cao/') > -1;
      },
      isAgencyPort: function() {
        var directory = aaaie.Analytics.currentURL()
          .attr('directory')
          .toLowerCase();
        return directory.indexOf('quickquote') > -1;
      },
      isSelfReg: function() {
        var directory = aaaie.Analytics.currentURL()
          .attr('directory')
          .toLowerCase();
        return directory.indexOf('fedregistration') > -1;
      },
      isAAAU: function() {
        return (
          aaaie.Analytics.currentHost().toLowerCase().indexOf('aaatv') > -1
        );
      },
      isCorp: function() {
        var directory = aaaie.Analytics.currentURL()
          .attr('directory')
          .toLowerCase();
        return directory.indexOf('content/aaa-ie/b2c') > -1;
      },
      isLCMS: function() {
        return (
          aaaie.Analytics.currentHost().toLowerCase().indexOf('kenexa') > -1
        );
      },
      isEZPAY: function() {
        var directory = aaaie.Analytics.currentURL()
          .attr('directory')
          .toLowerCase();
        return (
          directory.indexOf('selfservice/ezpay') > -1 ||
          directory.indexOf('paymybill') > -1
        );
      },
      isIdCard: function() {
        var directory = aaaie.Analytics.currentURL()
          .attr('directory')
          .toLowerCase();
        return directory.indexOf('idcard') > -1;
      },
      getWorkItemId: function() {
        if (ap && ap.transaction) {
          return ap.transaction.workItemId;
        }
      },
      getTitle: function() {
        if (this.isAgencyPort()) {
          if (ap && ap.page) {
            return ap.page.title;
          }
        } else if (this.isMPL() || this.isB2C() || this.isEZPAY()) {
          return aaaie.Analytics.contexts.page.getPageTitle();
        }
        return document.title;
      },
      getSource: function() {
        if (this.isAgencyPort()) {
          var sourceCode;

          if (aaaie.Analytics.currentURL().attr('source')) {
            sourceCode = aaaie.Analytics.currentURL().attr('source');
          }
          if (sourceCode) {
            aaaie.Cookies.set('aaaie.analytics.sourcecode', sourceCode);
          } else {
            sourceCode = aaaie.Cookies.get('aaaie.analytics.sourcecode');
          }
          return sourceCode;
        }
      },
      getClubCode: function() {
        var clubCode;

        if (this.isAgencyPort()) {
          if (aaaie.Analytics.currentURL().attr('clubcode')) {
            clubCode = aaaie.Analytics.currentURL().attr('clubcode');
          }
          if (clubCode) {
            aaaie.Cookies.set('aaaie.analytics.clubcode', clubCode);
          } else {
            clubCode = aaaie.Cookies.get('aaaie.analytics.clubcode');
          }
        }

        if (this.isAgentPortal() || this.isIPortal() || this.isEZPAY()) {
          clubCode = wms_user_info.riskStateNationalCode;
        }

        if (this.isMPL() || this.isB2C() || this.isEZPAY()) {
          clubCode = wms_customer_info.clubcode;
        }

        if (this.isIdCard() && mobileIdCard) {
          clubCode = mobileIdCard.clubCode;
        }

        return clubCode;
      },
      getQuickQuoteProduct: function() {
        var prodName;
        if (this.isAgencyPort()) {
          var directory = aaaie.Analytics.currentURL().attr('directory');
          if (directory.indexOf('agentId') > -1) {
            prodName = 'Agent Quick Quote';
            aaaie.Cookies.set('aaaie.analytics.quickquoteproduct', prodName);
          }
          if (aaaie.Cookies.get('aaaie.analytics.quickquoteproduct')) {
            prodName = aaaie.Cookies.get('aaaie.analytics.quickquoteproduct');
          } else {
            prodName = 'Consumer Quick Quote';
          }
        }
        return prodName;
      },
      getPageTitle: function() {
        var title = '';
        if (this.isAgencyPort()) {
          if (ap && ap.page) {
            return ap.page.title;
          }
        } else if (this.isB2C() || this.isEZPAY() || this.isMPL()) {
          if (aaaie.Analytics.config.pageTitle.trim() !== '') {
            title = aaaie.Analytics.config.pageTitle;
          } else if (
            aaaie.$('body').attr(aaaie.Analytics.config.documentTitle)
          ) {
            title = aaaie.$('body').attr(aaaie.Analytics.config.documentTitle);
          }
        } else {
          title = document.title;
        }
        return title;
      },
      getPageURL: function() {
        var path;
        if (aaaie.Analytics.config.pagePath.trim() !== '') {
          path = aaaie.Analytics.config.pagePath;
        } else if (aaaie.$('body').attr(aaaie.Analytics.config.documentUrl)) {
          path = aaaie.$('body').attr(aaaie.Analytics.config.documentUrl);
        } else {
          path = aaaie.$(location).attr('href');
        }
        return path;
      }
    },
    user: {
      /*
             var wms_user_info={
             id : "CN=George\, Arogia,OU=AAAClubs,OU=InsuranceExchange,dc=B2B,dc=CSAA,dc=PRI",
             ip : "null",
             agentId : "500008194",
             agentName : "Arogia George",
             isSweepEnabled : "false",
             defaultState: "",
             stateListStr: ""
             };
             */
      isAgent: function() {
        return (
          aaaie.Analytics.contexts.page.isAgentPortal() ||
          aaaie.Analytics.contexts.page.isIPortal()
        );
      },
      isConsumer: function() {
        return (
          aaaie.Analytics.contexts.page.isB2C() ||
          aaaie.Analytics.contexts.page.isAgencyPort() ||
          aaaie.Analytics.contexts.page.isMPL()
        );
      },
      isEmployee: function() {
        return (
          aaaie.Analytics.contexts.page.isB2E() ||
          aaaie.Analytics.contexts.page.isCAO() ||
          aaaie.Analytics.contexts.page.isLCMS()
        );
      },
      isPublic: function() {
        return aaaie.Analytics.contexts.page.isCorp();
      },
      getAgentId: function() {
        if (this.isAgent()) {
          return wms_user_info.agentId;
        }
      },
      getAgentName: function() {
        if (this.isAgent()) {
          return wms_user_info.agentName;
        }
      },
      getAgencyData: function() {
        if (this.isAgent()) {
          return (
            (wms_user_info.riskStateIdentifier || 'n/a') +
            ':' +
            (wms_user_info.riskStateFullName || 'n/a') +
            ':' +
            (wms_user_info.riskStateNationalCode || 'n/a') +
            ':' +
            wms_user_info.agentId +
            ':' +
            wms_user_info.agentName +
            ':' +
            (wms_user_info.AGNT_ST || 'n/a')
          );
        }
      },
      isEZPAY: function() {
        return aaaie.Analytics.contexts.page.isEZPAY();
      },
      getUserData: function() {
        if (
          aaaie.Analytics.contexts.page.isMPL() ||
          aaaie.Analytics.contexts.page.isB2C() ||
          aaaie.Analytics.contexts.page.isEZPAY()
        ) {
          return (
            (wms_customer_info.clubcode || 'n/a') +
            ':' +
            (wms_customer_info.eId || 'n/a') +
            ':' +
            (wms_customer_info.regId || 'n/a') +
            ':' +
            (wms_customer_info.userId || 'n/a') +
            ':' +
            (aaaie.Analytics.hashInfo(wms_customer_info.email) || 'n/a')
          );
        } else if (this.isAgent()) {
          return (
            (wms_user_info.riskStateIdentifier || 'n/a') +
            ':' +
            (wms_user_info.riskStateFullName || 'n/a') +
            ':' +
            (wms_user_info.riskStateNationalCode || 'n/a') +
            ':' +
            wms_user_info.agentId +
            ':' +
            wms_user_info.agentName +
            ':' +
            (wms_user_info.AGNT_ST || 'n/a')
          );
        } else if (aaaie.Analytics.contexts.page.isIdCard()) {
          return (
            (mobileIdCard.state.riskState || 'n/a') +
            '-' +
            (mobileIdCard.zipCode || 'n/a')
          );
        } else {
          return 'N/A';
        }
      },
      isIdCard: function() {
        return aaaie.Analytics.contexts.page.isIdCard();
      }
    },
    validationErrorMap: function() {
      //var map = new Object();
      return {};
    }
  },

  /**
   * Derives the category of analytic event based on the current
   * context of the page and user.
   *
   * Returns the category in a colon-separated namespace.
   */
  getCategory: function() {
    var category = '';
    var contexts = aaaie.Analytics.contexts;
    var page = contexts.page;
    category += this.getProductType() + ':';
    category += this.getUserType() + ':';
    category += page.getPageTitle();
    return category;
  },

  /**
   * Derives the product of the analytic event based on the current
   * context of the page.
   */
  getProductType: function() {
    var contexts = aaaie.Analytics.contexts;
    var page = contexts.page;
    return page.isAgentPortal()
      ? 'Agent Portal'
      : page.isIPortal()
      ? 'Insurance Portal'
      : page.isSelfReg()
      ? 'Consumer - Self Registration'
      : page.isAAAU()
      ? 'AAAU'
      : page.isLCMS()
      ? 'LCMS'
      : page.isB2C()
      ? 'Consumer'
      : page.isMPL()
      ? 'Consumer MyPolicy Login'
      : page.isEZPAY()
      ? 'EZPAY'
      : page.isB2E()
      ? 'Passport'
      : page.isCAO()
      ? 'CAO'
      : page.isAgencyPort()
      ? 'Quick Quote'
      : page.isCorp()
      ? 'Corporate Site'
      : page.isIdCard()
      ? 'Mobile Id Card'
      : 'Uncategorized Page';
  },

  /**
   * Derives the user of the analytic event based on the current
   * contexts of the page and user.
   */
  getUserType: function() {
    var contexts = aaaie.Analytics.contexts;
    var user = contexts.user;
    var page = contexts.page;
    return user.isAgent()
      ? 'Agent'
      : (user.isConsumer() || user.isEZPAY()) && !page.isAgencyPort()
      ? 'Customer'
      : user.isConsumer() && page.isAgencyPort()
      ? 'Prospect'
      : user.isEmployee()
      ? 'Employee'
      : user.isIdCard
      ? 'Mobile Id Customer-' + user.getUserData()
      : page.isAAAU()
      ? 'AAAU User'
      : user.isPublic()
      ? 'Public'
      : 'Uncategorized User';
  },

  /**
   * This is the base analytics call that interfaces with the
   * Google Analytics global object.
   */
  push: function(category, action, label, value, interactive) {
    /*
     * This is the API to GTM which is simply a proxy to the underlying GA API that
     * GTM will use as a part of the tag type.
     */
    if (window[aaaie.Analytics.config.globalDataLayer]) {
      window[aaaie.Analytics.config.globalDataLayer].push({
        event: 'aaaieEvent',
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        eventValue: value,
        eventInteractive: interactive ? 0 : 1
      });
    } else {
      if (aaaie.debug) {
        // eslint-disable-next-line no-console
        console.warn(
          'The global AAAIE Google Analytics dataLayer object was not found. Currently configured to find: ' +
            aaaie.Analytics.config.globalDataLayer
        );
      }
    }
  },

  globalGA: function() {
    return this.config.globalGA;
  },

  globalDataLayer: function() {
    return this.config.globalDataLayer;
  },

  trackingId: function() {
    var analytics = aaaie.Analytics;
    var host = analytics.currentHost();
    if (aaaie.Analytics.contexts.page.isEZPAY()) {
      //EZPAY
      return this.getGATagForEZPay(host);
    } else if (aaaie.Analytics.contexts.page.isIPortal()) {
      //Insurance Portal
      return '';
    } else {
      //default
      return this.getGATag(host);
    }
  },

  getGATagForEZPay: function(host) {
    switch (host) {
      case 'csaa-insurance.aaa.com':
        return 'UA-76381458-1'; // Production
      case 'e0.pncie.com':
      case 'e1.pncie.com':
      case 'e6.pncie.com':
      case 'e2.pncie.com':
        return 'UA-76381458-4'; // QA Development
      case 'e3.pncie.com':
        return 'UA-76381458-2'; // Performance
      default:
        return 'UA-76381458-3'; // Local Development
    }
  },

  /**
   * Returns the correct tracking ID for the current context
   * and domain.
   *
   * Production:  UA-45174401-1
   * QA:          UA-45174401-2
   * Development: UA-45174401-3
   * Performance: UA-45174401-4
   * Local:       UA-45174401-5
   */
  getGATag: function(host) {
    switch (host) {
      case 'mypolicy.digital.csaa-insurance.aaa.com':
      case 'csaa-insurance.aaa.com':
      case 'aaatv.com':
      case 'www.aaatv.com':
      case 'goaaa.kenexa.com':
        return 'UA-45174401-1'; // Production
      case 'aaa4insurance.com':
        return 'UA-45174401-1'; // Production
      case 'e1.pncie.com':
      case 'e6.pncie.com':
      case 'stageaaa.kenexa.com':
        return 'UA-45174401-2'; // QA
      case 'e2.pncie.com':
        return 'UA-45174401-2'; // QA
      case 'e0.pncie.com':
      case 'n01awu712':
      case 'mypolicy.dev.digital.pncie.com':
      case 'devaaa.kenexa.com':
        return 'UA-45174401-3'; // Development
      case 'mypolicy.perf.digital.pncie.com':
      case 'e3.pncie.com':
        return 'UA-45174401-4'; // Performance
      default:
        return 'UA-45174401-5'; // Local Development
    }
  },

  /**
   * Analytics call that is based on a user interaction.
   */
  trackInteraction: function(action, label, value) {
    var category = aaaie.Analytics.getCategory();
    aaaie.Analytics.push(category, action, label, value, true);
  },

  /**
   * Analytics call that is based on an event within code.
   */
  trackEvent: function(action, label, value) {
    var category = aaaie.Analytics.getCategory();
    aaaie.Analytics.push(category, action, label, value, false);
  },

  /**
   * Returns an event handler configured for the action/label of any low-level
   * event (both imperative and declarative).
   *
   * This function will inspect the current element for a number of identifying values:
   *
   * Action: This will be determined by the custom attribute "data-analytics-action" first, then by name, then id then class.
   * Label: This will be determined by the custom attribute "data-analytics-label" first, then by the elements value if it exists and the
   * custom attribute "data-analytics-send" is declared as well.
   *
   * The Label is an opt-in mechanism. It will not be sent unless there is the custom attribute to send.
   */
  defaultLowLevel: function(action, label, imperative) {
    return function(evt) {
      var current;
      if (aaaie.$(this) === undefined || aaaie.$(this)[0] === window) {
        current = aaaie.$(evt.target);
      } else {
        current = aaaie.$(this);
      }

      if (aaaie.$(this).is('body')) {
        label = aaaie.$(evt.target)['0'].nodeName;
        current = aaaie.$(evt.target);
      }

      var elementName =
        current.attr('name') ||
        current.attr('id') ||
        current.attr('class') ||
        current.text();
      var elementVal =
        current.attr(aaaie.Analytics.config.sendValueAttribute) &&
        current.val() &&
        current.val().length > 0
          ? current.val()
          : null;

      // Now with the element info create an action and a label considering
      // if the element is declared with special values for each.
      if (!current.attr(aaaie.Analytics.config.actionAttribute)) {
        var actionAttributeList = current
          .parents()
          .map(function(i, n) {
            return aaaie.$(n).attr(aaaie.Analytics.config.actionAttribute);
          })
          .get()
          .reverse();
        action = actionAttributeList.toString().replace(/,/g, '-');
      }
      action =
        current.attr(aaaie.Analytics.config.actionAttribute) ||
        action ||
        elementName;
      label =
        current.attr(aaaie.Analytics.config.labelAttribute) ||
        elementVal ||
        label;

      var parentListing = current
        .parents()
        .map(function(i, n) {
          return aaaie.$(n).attr(aaaie.Analytics.config.labelAttribute);
        })
        .get()
        .reverse();

      label = aaaie.Analytics.formatLabel(label, parentListing);

      //NOTE: The default low level handler does not send the "value" param because it's too low level
      //to really discern that kind of data. This is an acceptable circumstance.
      action = ifPresentRemoveUrl(action);

      if (imperative) {
        aaaie.Analytics.trackEvent(action, label);
      } else {
        aaaie.Analytics.trackInteraction(action, label);
      }
    };
  },

  formatLabel: function(label, parents) {
    if (parents.length > 0) {
      label = parents + (label ? ',' + label : '');
    }

    label = label.replace(/,/g, '-');
    label = aaaie.Analytics.contexts.page.getPageTitle() + '-' + label;
    label = ifPresentRemoveUrl(label);

    return label;
  }
};

/**
 * Removes url if present due to iFrame issues on third party websites.
 *
 * @param action
 * @returns action whithout the appended url
 */
var ifPresentRemoveUrl = function(value) {
  if (value && value.indexOf('https') > -1) {
    return value.slice(0, value.indexOf('https') - 3);
  }
  return value;
};

aaaie.$(document).ready(function() {
  if (aaaie.Analytics.contexts.page.isIPortal()) {
    return;
  }

  //Load the GTM Tag
  aaaie.Analytics.loadGTM(
    aaaie.Analytics.config.globalDataLayer,
    aaaie.Analytics.config.globalGTMTagId
  );

  /**
   * The "engine" part of analytics.
   */
  aaaie
    .$('body')
    .on(
      'mousedown click',
      'input[type=button], input[type=submit], img, button, a',
      function(e) {
        if (e.which != 1) {
          return;
        }
        aaaie.Analytics.defaultLowLevel('click-event', 'button/anchor')(e, {});
      }
    );
  aaaie
    .$('body')
    .on(
      'mousedown click',
      'input[type=radio], input[type=checkbox]',
      function(e) {
        if (e.which != 1) {
          return;
        }
        aaaie.Analytics.defaultLowLevel('click-event', 'radio/checkbox')(e, {});
      }
    );
  aaaie
    .$('body')
    .on(
      'change',
      'select',
      aaaie.Analytics.defaultLowLevel('change-event', 'select')
    );
  aaaie
    .$('body')
    .on(
      'change',
      'input[type=text], input[type=number], input[type=tel],textarea',
      function(evt) {
        // Only fire the analytics event if the field is not explicitly ignored in the HTML
        if (
          aaaie.$(this).attr(aaaie.Analytics.config.ignoreElement) !== 'true'
        ) {
          aaaie.Analytics.defaultLowLevel('change-event', 'text')(evt, {});
        }
      }
    );

  aaaie.$('body').on('validationError', function(event) {
    if (event.target.innerText !== undefined) {
      if (
        aaaie.Analytics.contexts.validationErrorMap[event.target.innerText] ===
          undefined ||
        aaaie.Analytics.contexts.validationErrorMap[event.target.innerText] ===
          ''
      ) {
        aaaie.Analytics.contexts.validationErrorMap[
          event.target.innerText
        ] = new Date().getTime();
        aaaie.Analytics.defaultLowLevel(
          'validationError',
          event.target.innerText
        )(event, {});
      } else {
        var timeBefore =
          (new Date().getTime() -
            aaaie.Analytics.contexts.validationErrorMap[
              event.target.innerText
            ]) /
          1000;

        if (timeBefore > 15) {
          aaaie.Analytics.defaultLowLevel(
            'validationError',
            event.target.innerText
          )(event, {});
          aaaie.Analytics.contexts.validationErrorMap[
            event.target.innerText
          ] = new Date().getTime();
        }
      }
    }
  });
  aaaie.$('body').on('mousedown', function(event) {
    //If NOT Left Click return
    if (event.which != 1) {
      return;
    }
    var excludeElements = ['IMG', 'INPUT', 'TEXTAREA', 'SELECT', 'BUTTON', 'A'];
    var nodeName = event.target.nodeName; //SPAN
    var registerElement = false;
    for (var i = 0; i < excludeElements.length; i++) {
      if (nodeName !== excludeElements[i]) {
        registerElement = true;
      } else {
        registerElement = false;
        break;
      }
    }
    if (registerElement && nodeName) {
      var target = aaaie.$(event.target),
        currentAnalyticsLabel = target.attr(
          aaaie.Analytics.config.labelAttribute
        );

      var parentAnalyticsLabel = target
        .parents()
        .map(function(i, n) {
          return aaaie.$(n).attr(aaaie.Analytics.config.labelAttribute);
        })
        .get(0);

      if (currentAnalyticsLabel || parentAnalyticsLabel) {
        aaaie.Analytics.defaultLowLevel('click-event', currentAnalyticsLabel)(
          event,
          {}
        );
      }
    }
  });

  aaaie
    .$('body')
    .on(
      aaaie.Analytics.config.customEventName,
      aaaie.Analytics.defaultLowLevel('custom-event', 'custom', true)
    );
});
