/**
 * Collection of Widgets/Components
 *
 * @type {Object}
 */
aaaie.Widgets = {
  config: {
    creditCard: {
      version: '0.0.2'
    },
    accordion: {
      active: false,
      animate: {
        easing: 'swing',
        duration: 300
      },
      collapsible: true,
      disabled: false,
      event: 'click',
      header: '.aaaie-accordion-header',
      heightStyle: 'content',
      icons: {
        header: 'ui-icon-triangle-1-e',
        headerSelected: 'ui-icon-triangle-1-s'
      },
      multiPanel: true,
      allOpen: true
    },
    displayVisible: {
      attribute: 'aaaie-widgets-display-visible', //value is mapped to type property
      verticalActionAttribute: 'aaaie-widgets-display-visible-vertical', //value is mapped to vertical property
      horizontalActionAttribute: 'aaaie-widgets-display-visible-horizontal', //value is mapped to horizontal property
      type: 'both', //Possible Values: both, horizontal, vertical, none
      vertical: 'move', //Possible Values: scroll, move
      horizontal: 'move', //Possible Values: scroll, move
      scrollSpeed: 300 //ms value for controlling auto page scrolling speed
    },
    dropdown: {
      buttonClass: 'dropdown',
      contentIdAttribute: 'data-aaaie-dropdown-content-id',
      contentId: null,
      content: null, //HTML content
      template: {
        template: null,
        options: null
      },
      dropdownClass: 'f-dropdown',
      isHover: false,
      hoverTimeOut: 5000
    },
    /* Creates a menu Object with the different values.
     * @cfg {null} breadcrumbTemplate
     * @cfg {string} breadcrumbClass
     * @cfg {null} breadcrumbContent
     * @cfg {null} breadcrumbContentId
     * @cfg {string} menuType [menuType=breadcrumb]
     *
     * */
    menu: {
      breadcrumbTemplate: null,
      breadcrumbClass: 'aaaie-breadcrumb',
      breadcrumbContent: null,
      breadcrumbContentId: null,
      menuType: 'breadcrumb' //Possible Values: breadcrumb, (left?), (top?), (...) //TODO: set defaults for other menu types
    },
    backToTop: {
      cssClass: 'right aaaie-jcl-back-to-top',
      scrollSpeed: 'slow',
      id: 'aaaie-jcl-back-to-top'
    },
    slider: {
      lowerToolTipFunc: null,
      upperToolTipFunc: null
    },
    carousel: {
      arrows: false,
      infinite: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      adaptiveHeight: true,
      mobileFirst: true
    }
  },

  formatPhone: function(number, areaCode) {
    number = this.onlyNumbers(number);
    var formatedNumber = '',
      index = 0,
      length = number.length - 1;

    while (index < number.length) {
      if (
        (index === 6 || index === 3 || index === 9) &&
        index < number.length - 1
      ) {
        formatedNumber = '.' + number[length--] + formatedNumber;
      } else {
        formatedNumber = number[length--] + formatedNumber;
      }
      index++;
    }

    if (areaCode && formatedNumber.length === 8) {
      formatedNumber = areaCode + '.' + formatedNumber;
    }

    if (formatedNumber && formatedNumber.length > 7) {
      return formatedNumber;
    } else {
      return 'Not on file';
    }
  },

  onlyNumbers: function(numberString) {
    if (!numberString) {
      return '';
    }
    return numberString.replace(/[^0-9]/g, '');
  },

  autoTemplate: function(elem, settings) {
    var config = aaaie.Widgets.config.autoTemplate;
    aaaie.$.extend(true, config, settings);
  },

  backToTop: function(elem, options) {
    var config = aaaie.Widgets.config.backToTop;
    aaaie.$.extend(true, config, options);
    if (!elem) elem = aaaie.$('body');
    var button = aaaie.$(
      '<div id="' +
        config.id +
        '" class="' +
        config.cssClass +
        '" style="display:none;"></div>'
    );
    elem.append(button);
    window.onscroll = function() {
      // cross-browser compatible scrollTop.
      var scrollTop =
        window.pageYOffset !== undefined
          ? window.pageYOffset
          : (
              document.documentElement ||
              document.body.parentNode ||
              document.body
            ).scrollTop;
      if (scrollTop > 0) button.fadeIn('slow');
      else button.fadeOut('slow');
    };
    button.click(function() {
      aaaie.$('html, body').animate(
        {
          scrollTop: 0
        },
        config.scrollSpeed
      );
    });
  },

  /**
   * Injects a Pagination component into an HTML ELement.
   * @param  {HTMLElement} elem    the element that is the container for the pagination
   * @param  {Object} options maxPages, position, reloadPage
   */
  paginate: function(elem, options) {
    'use strict';
    var element = aaaie.$(elem),
      maxPages = options.maxPages || 5,
      displayPages = options.displayPages || 3,
      position = options.position || 'centered',
      reloadPage = options.reloadPage,
      pageQueryKey = parseUri(document.location).queryKey.p,
      page = parseInt(pageQueryKey, 10) || options.page || 1,
      href = reloadPage ? '?p={{n}}' : '#';
    var template = '<div class="pagination pagination-' + position + '">';
    template += '<ul>';
    template += '{{#paginate pagination type="previous"}}';
    template +=
      '<li {{#if disabled}}class="disabled"{{/if}}><a href="' +
      href +
      '" data-aaaie-page="{{n}}">&lt; Previous</a></li>';
    template += '{{/paginate}}';
    template +=
      '{{#paginate pagination type="middle" limit="' + displayPages + '"}}';
    template +=
      '<li class="{{#if active}}active{{/if}} {{#if disabled}}disabled{{/if}}">';
    template +=
      '{{#unless disabled}}<a href="' +
      href +
      '" data-aaaie-page="{{n}}">{{p}}</a>{{/unless}}{{#if disabled}}<span>{{p}}</span>{{/if}}</li>';
    template += '{{/paginate}}';
    template += '{{#paginate pagination type="next"}}';
    template +=
      '<li {{#if disabled}}class="disabled"{{/if}}><a href="' +
      href +
      '" data-aaaie-page="{{n}}">Next &gt;</a></li>';
    template += '{{/paginate}}';
    template += '</ul>';
    template += '</div>';

    function applyPaginationTemplate(elem, template, options) {
      aaaie.Templating.renderInto(elem, template, options);
      captureClick();
    }

    function captureClick() {
      element.find('a').on('click', function() {
        var page = parseInt($(this).attr('data-aaaie-page'), 10),
          previous = parseInt(
            element.find('li.active a').attr('data-aaaie-page'),
            10
          );
        element.triggerHandler('paginated', {
          page: page,
          previousPage: previous
        });
        if (!reloadPage) {
          applyPaginationTemplate(elem, template, {
            pagination: {
              page: page,
              pageCount: maxPages
            }
          });
          return false;
        }
      });
    }

    applyPaginationTemplate(elem, template, {
      pagination: {
        page: page,
        pageCount: maxPages
      }
    });
  },

  //  /**
  //   * Injects a credit card input component into an HTML ELement.
  //   * @param  {HTMLElement} elem    the element that is the container for the credit card element
  //   * @param  {Object} options cssClass, help and errorMsg values
  //   */
  //  creditCard: function (elem, options) {
  //    'use strict';
  //    $.extend(options, aaaie.config);
  //    var element = aaaie.$(elem),
  //      cssClass = options.cssClass || '',
  //      help = options.help || 'Enter a valid credit card number.',
  //      showCCHelp = (options.hasOwnProperty('showCCHelp') ? options.showCCHelp : true ),
  //      errorMsg = options.errorMsg || 'Incorrect credit card number.',
  //      name = options.inputName || 'aaaie-widget-credit-card',
  //      pattern = options.pattern || 'creditCardAll',
  //      mask = options.mask || 'creditCard';
  //    var template = '<input name="' + name + '" class="aaaie-widgetCC ' + cssClass + '" maxlength="20" size="22" required data-aaaie-pattern="' + pattern + '" data-aaaie-mask="' + mask + '" ';
  //    template += 'data-error-message="' + errorMsg + '" />';
  //    element.append(template);
  //    if (showCCHelp) aaaie.$('[name="' + name + '"]').attr('data-aaaie-help', help);
  //  },

  creditCard: function(elem, options) {
    var config = aaaie.Widgets.config.creditCard;
    $.extend(true, config, options);
    return aaaie.Widgets.creditCard[config.version](elem, options);
  },

  /**
   * Injects a lightbox window component into an HTML ELement.
   * @param  {HTMLElement} elem    the element that is the container for the lightboxwindow to be show/hide
   * @param  {Object} options action, target container
   */
  lightboxwindow: function(elem, options) {
    var element = aaaie.$(elem),
      windowstate = options.action,
      windowheightoverlay = aaaie.$(document).height(),
      windowwidthoverlay = aaaie.$(window).width();

    if (windowstate == 'open') {
      element.fadeTo('medium', 0.6).css({
        width: windowwidthoverlay,
        height: windowheightoverlay
      });
    } else if (windowstate == 'close') {
      element.fadeOut();
    }
  },

  pageMessage: function(elem, options) {
    var element = aaaie.$(elem),
      closeMessage = options.closeMessage || 'OK',
      closeAction = options.closeAction || '',
      fadeDelay = options.fadeDelay || 5000,
      cssClass = options.cssClass || 'aaaie-page-message',
      ackAction =
        (typeof options.ackAction == 'function' ? options.ackAction : null) ||
        function() {
          aaaie.$(this).parent().parent().remove();
          //TO DO: log acknowledge action to server
        },
      template;
    if (typeof options.template == 'function') {
      template = options.template();
    } else if (typeof options.template == 'string') {
      template = options.template;
    }
    template =
      '<div class="' +
      cssClass +
      '"><div class="' +
      cssClass +
      '-icon">&nbsp;</div><div class="' +
      cssClass +
      '-text">' +
      template +
      '</div>';
    switch (closeAction) {
      case 'ack':
        template +=
          '<div class="' +
          cssClass +
          '-ack"><a class="' +
          cssClass +
          '-ack-link" href="#">' +
          closeMessage +
          '</a></div></div>';
        element.append(template);
        aaaie.$('.' + cssClass + '-ack-link').click(ackAction);
        break;
      case 'click':
        template +=
          '<div class="' +
          cssClass +
          '-close"><a class="' +
          cssClass +
          '-close-link" href="#">' +
          closeMessage +
          '</a></div></div>';
        element.append(template);
        aaaie.$('.' + cssClass + '-close-link').click(function() {
          $(this).parent().parent().remove();
        });
        break;
      case 'fade':
        element.append(
          '<div class="' + cssClass + '-fade">' + template + '</div></div>'
        );
        aaaie.$(document).click(function() {
          aaaie
            .$('.' + cssClass + '-fade')
            .parent()
            .delay(fadeDelay)
            .fadeOut('slow');
        });
        break;
      default:
        element.append(template + '</div>');
      //TO DO: Default behavior remain until page refresh?
    }
  },

  //TO DO: Test against servlet once created
  //Servlet needs to return the list of messages according to the options object of the pageMessage function
  loadPageMessages: function(elem, options) {
    var url = options.url || 'TO DO: add default page',
      params = options.params || {};
    if (!params[url]) params[url] = window.location.href;
    $.getJSON(url, options.params, function(results) {
      $.each(results, function(i, message) {
        aaaie.Widgets.pageMessage(elem, message);
      });
    });
  },

  //ZA: Brought in from aaaie-full, converted to API call by requiring elem as a parameter
  selectToTabs: function(elem, settings) {
    var selectElement = aaaie.$(elem);
    $.extend(settings, aaaie.config);

    // get the options
    var selectOptions = (function() {
      var options = [];
      selectElement.children('option').each(function() {
        options.push({
          value: aaaie.$(this).attr('value'),
          selected: aaaie.$(this).attr('selected') == 'selected',
          label: aaaie.$(this).text()
        });
      });
      return options;
    })();

    //create the tab container
    var tabContainer = jQuery('<ul class="wms-tab-selects"></ul>');

    //add tab for each option
    aaaie.$(selectOptions).each(function(i) {
      var listClass = i === 0 ? 'wms-tab-firstitem ' : '';
      listClass += this.selected ? 'wms-tab-selected' : '';
      if (i === 0) {
        tabContainer.append(
          '<li class="' +
            listClass +
            '"><span class="search-sharkfin_nav"></span><span class="wms-small-link">' +
            this.label +
            '</span></li>'
        );
      } else {
        tabContainer.append(
          '<li class="' +
            listClass +
            '"><span class="wms-small-link">' +
            this.label +
            '</span></li>'
        );
      }
      var currentLink = tabContainer
        .children('li:nth-child(' + (i + 1) + ')')
        .children('span');
      currentLink.on(
        'click',
        {
          selectId: selectElement.attr('id'),
          optionIndex: i
        },
        updateSelectOption
      );
    });

    tabContainer.insertAfter(selectElement);

    //handle click events
    function updateSelectOption(event) {
      var newSelectElement = aaaie.$('#' + event.data.selectId);
      newSelectElement
        .find('option')
        .eq(event.data.optionIndex)
        .prop('selected', 'selected');
      newSelectElement.change();
      //update the source and target
      var parentListItem = event.target.parentNode;
      var parentList = parentListItem.parentNode;

      aaaie
        .$(parentList)
        .children('li.wms-tab-selected')
        .removeClass('wms-tab-selected');
      //console.log($(parentList).children('li').length);

      aaaie.$(parentList).each(function() {
        aaaie
          .$(this)
          .children('li')
          .children('span.search-sharkfin_nav')
          .remove();
      });
      aaaie.$(parentListItem).addClass('wms-tab-selected');
      aaaie
        .$('.wms-tab-selected')
        .prepend('<span class="search-sharkfin_nav"></span>');
    }
    return elem;
  },

  /**
   * Accordion - a UI Control with a header, and collapsible content panels to present
   *    information in a limited space.  Based on [jQuery UI Accordion][1].
   *    !!! NOTE: the jQuery UI accordion has been modified, be sure to persist changes during upgrade ZA
   * [1]: http://jqueryui.com/accordion/
   *
   * @param {Object} elem A DOM Element with attribute data-aaaie-widget="accordion".
   *   Also needs a an element with class="aaaie-accordion-header" inside the accordion.
   * @param {Object} settings An object with options for the accordion.  Most are passed to jQuery UI.
   *
   * @cfg {bool} allOpen show accordion as open on page load.  default is true.  Overrides the allClosed argument.
   * @cfg {bool} allClosed show accordion as all closed.
   * @cfg {bool} multiPanel allow more than one content panel to be open at the same time.
   * @cfg {Object} animate an object that takes easing and duration.  Passed to jQuery UI http://api.jqueryui.com/accordion/#option-animate
   */
  accordion: function(elem, settings) {
    //NOTE: the jQuery UI accordion has been modified, be sure to persist changes during upgrade ZA
    $.extend(settings, aaaie.Widgets.config.accordion);
    if (settings.allOpen) {
      var oldAnimate = settings.animate;
      settings.animate = false;
      aaaie.$(elem).accordion(settings);
      aaaie.$(elem).find('.ui-accordion-header').click();
      aaaie.$(elem).accordion('option', 'animate', oldAnimate);
    } else aaaie.$(elem).accordion(settings);
    //TODO: Add events
  },

  //TODO: test template driven content
  dropdown: function(elem, settings) {
    var config = aaaie.Widgets.config.dropdown;
    $.extend(true, config, settings);
    aaaie.$(elem).addClass(config.buttonClass);
    var contentId = config.contentId;
    var content = config.content;
    if (!contentId && !config.content && config.template.template) {
      content = aaaie.Templating.render(config.template, config.options);
    }
    if (!contentId && content) {
      contentId = 'aaaie-dropdown-' + Math.random().toString(36).substring(7);
      aaaie
        .$('<div/>', {
          id: contentId,
          class: config.dropdownClass
        })
        .html(content)
        .appendTo('body');
    }
    aaaie.$(elem).attr('data-dropdown', contentId);
    aaaie.$('#' + contentId).attr('data-dropdown-content', '');
    aaaie.$('#' + contentId).addClass('f-dropdown');

    // on hover dropdown open
    if (config.isHover) {
      aaaie
        .$(elem)
        .attr(
          'data-options',
          'is_hover:true; hover_timeout:' +
            aaaie.Widgets.config.dropdown.hoverTimeOut +
            ';'
        );
    }
    // added for new dropdown change. on click of select dropdown value and needs to close KP.
    aaaie.$('#' + contentId).on('click.fndtn.dropdown', function(e) {
      var targetHtml = $(e.target)
        .closest('[data-dropdown-content-val]')
        .html();
      if ($(this).prev().find('[data-dropdown-selected-val]').length > 0) {
        $(this).prev().find('[data-dropdown-selected-val]').html(targetHtml);
      }
      Foundation.libs.dropdown.close($(this));
    });
    //TODO: Add close link?, Add events beyond standard foundation events?, Add option to keep visible (displayVisible) and test?
  },

  menu: function(elem, settings) {
    //JSON Format:  {"links":[{"href":"/page.html","name":"Page Name","class":"active","links":[]}]}      ZA
    //links can have sub links (the links property) which contain the same properties at every level, no limit on levels       ZA
    var config = aaaie.Widgets.config.menu;
    var content, template, cssClass;
    $.extend(true, config, settings);

    if (config.breadcrumbContentId) {
      content = $.parseJSON($('#' + config.breadcrumbContentId).text());
    } else if (config.breadcrumbContent) {
      content = $.parseJSON(config.breadcrumbContent);
    }

    if (config.menuType === 'breadcrumb') {
      template = config.breadcrumbTemplate;
      if (!template) {
        //breadcrumbs can't have sub links, so ignore them ZA
        template =
          '<ul>{{#each links}}<li {{#if class}}class="{{class}}"{{/if}}>{{#if href}}<a href="{{href}}">{{text}}</a>{{else}}{{text}}{{/if}}</li>{{/each}}</ul>';
      }
      cssClass = config.breadcrumbClass || 'aaaie-breadcrumbs';
    }
    //else if {} TODO: add other menu types and default templates
    //use handlebars partial templates for recursion through sub-links

    if (content && template) {
      aaaie.$(elem).trigger('aaaie-creatingMenu', config);
      aaaie.Templating.renderInto(elem, template, content);
      aaaie.$(elem).addClass(cssClass);
      aaaie.$(elem).trigger('aaaie-menuCreated');
    } else if (aaaie.debug) {
      // eslint-disable-next-line no-console
      console.log('Menu content and/or template not defined.', false);
    }
  },

  displayVisible: function(elem, settings) {
    settings.type =
      settings.type || aaaie.Widgets.config.displayVisible.type || 'both';
    settings.vertical =
      settings.vertical ||
      aaaie.Widgets.config.displayVisible.vertical ||
      'scroll';
    settings.horizontal =
      settings.horizontal ||
      aaaie.Widgets.config.displayVisible.horizontal ||
      'move';

    function moveHorizontal(elem) {
      var hidden, viewportWidth, scrollSpeed, $elem;
      $elem = aaaie.$(elem);
      scrollSpeed = aaaie.Widgets.config.displayVisible.scrollSpeed || 300;
      viewportWidth = window.innerWidth || aaaie.$(window).width();
      if (jQuery.browser.msie) {
        if (parseInt(jQuery.browser.version, 10) === 7) {
          viewportWidth -= 3;
        }
      }

      hidden = false;
      if (!$elem.is(':visible')) {
        $elem.css('visibility', 'hidden');
        $elem.css('display', 'block');
        hidden = true;
      }
      //TODO Move declarations to top
      var offset = $elem.offset().left + $elem.outerWidth(true) + 10;
      var left = $elem.offset().left;
      var clientWidth = $elem.outerWidth(true);

      if (hidden) {
        $elem.css('display', 'none');
        $elem.css('visibility', 'visible');
      }

      if (
        offset > viewportWidth + aaaie.$(window).scrollLeft() ||
        (($elem.is('.tip-left, .tip-top, .tip-bottom') ||
          ($elem.hasClass('tooltip') &&
            !$elem.is('.tip-left, .tip-right, .tip-top'))) &&
          left < aaaie.$(window).scrollLeft())
      ) {
        var ie6offset =
          $.browser.msie && $.browser.version.substr(0, 1) < 7 ? 30 : 0;
        if (!$elem.hasClass(aaaie.Help.config.dplCssClass)) {
          if (
            settings.horizontal === 'move' ||
            left + clientWidth > aaaie.$(document).width()
          ) {
            $elem.css({
              display: 'block',
              left: ''
            });
            $elem.css({
              left: left - (offset + 10 - viewportWidth) - ie6offset
            });
          } else if (settings.horizontal === 'scroll') {
            aaaie.$('html, body').animate(
              {
                scrollLeft: left
              },
              scrollSpeed
            );
          }
        } else {
          if (
            $elem.is('.tip-top, .tip-bottom') ||
            ($elem.hasClass('tooltip') &&
              !$elem.is('.tip-left, .tip-right, .tip-top'))
          ) {
            $elem.css({
              display: 'block',
              left: ''
            });
            var $nub = $elem.find('.nub');
            if (left < aaaie.$(window).scrollLeft()) {
              $elem.css({
                left: aaaie.$(window).scrollLeft() + 5
              });
              $nub.css('left', 2);
            } else {
              $elem.css({
                left:
                  left -
                  (offset + 10 - viewportWidth) -
                  ie6offset +
                  aaaie.$(window).scrollLeft()
              });
              $nub.css(
                'left',
                5 +
                  (offset + 10 - viewportWidth) -
                  ie6offset -
                  aaaie.$(window).scrollLeft()
              );
            }
          } else {
            if (
              settings.horizontal === 'move' ||
              left + clientWidth >
                viewportWidth + aaaie.$(window).scrollLeft() + 20
            ) {
              var $target = aaaie.$(
                '.has-tip[data-selector="' + $elem.attr('data-selector') + '"]'
              );
              if (!$target.hasClass('tip-left')) {
                $target.removeClass('tip-right').addClass('tip-left');
                $elem.remove();
                Foundation.libs.tooltips.showOrCreateTip($target);
                var $newElem = Foundation.libs.tooltips.getTip($target);
                $newElem.attr('data-original-tip', 'tip-right');
              }
            } else if (settings.horizontal === 'scroll') {
              if ($elem.hasClass('tip-right'))
                aaaie.$('html, body').animate(
                  {
                    scrollLeft: left + clientWidth - aaaie.$(window).width()
                  },
                  scrollSpeed
                );
              else if ($elem.hasClass('tip-left'))
                aaaie.$('html, body').animate(
                  {
                    scrollLeft: left
                  },
                  scrollSpeed
                );
            }
          }
        }
      } else {
        if (
          $elem.hasClass(aaaie.Help.config.dplCssClass) &&
          ($elem.is('.tip-top, .tip-bottom') ||
            ($elem.hasClass('tooltip') &&
              !$elem.is('.tip-left, .tip-right, .tip-top')))
        ) {
          var $anub = $elem.find('.nub');
          $anub.css('left', 10);
        } else if ($elem.attr('data-original-tip') === 'tip-right') {
          var $atarget = aaaie.$(
            '.has-tip[data-selector="' + $elem.attr('data-selector') + '"]'
          );
          $atarget.removeClass('tip-left').addClass('tip-right');
          $elem.remove();
          Foundation.libs.tooltips.showOrCreateTip($atarget);
        }
      }
    }

    function moveVertical(elem) {
      var hidden, viewportHeight, scrollSpeed, $elem;
      $elem = aaaie.$(elem);
      scrollSpeed = aaaie.Widgets.config.displayVisible.scrollSpeed || 300;
      viewportHeight = window.innerHeight || aaaie.$(window).height();
      if (jQuery.browser.msie) {
        if (parseInt(jQuery.browser.version, 10) === 7) {
          viewportHeight -= 3;
        }
      }

      hidden = false;
      if (!$elem.is(':visible')) {
        $elem.css('visibility', 'hidden').css('display', 'block');
        hidden = true;
      }

      //TODO move declarations to top
      var $nub = $elem.find('.nub');
      $nub.css('top', 'auto');
      var elemMarginTop = parseInt($elem.css('margin-top'), 10);
      var offset = $elem.offset().top + $elem.outerHeight(true) + 10;
      var top = $elem.offset().top;
      var clientHeight = $elem.outerHeight(false);
      var nubTop = clientHeight / 2 - $nub.outerHeight(false) / 2 + 5;

      if (hidden) {
        $elem.css('display', 'none');
        $elem.css('visibility', 'visible');
      }

      if (
        offset > viewportHeight + aaaie.$(window).scrollTop() ||
        ($elem.is('.tip-top, .tip-left, .tip-right') &&
          top < aaaie.$(window).scrollTop())
      ) {
        var ie6offset =
          $.browser.msie && $.browser.version.substr(0, 1) < 7 ? 30 : 0;
        if (!$elem.hasClass(aaaie.Help.config.dplCssClass)) {
          if (
            settings.vertical === 'move' ||
            top + clientHeight > aaaie.$(document).height()
          ) {
            $elem.css({
              display: 'block',
              top: ''
            });
            $elem.css({
              top: top - (offset - viewportHeight + 10) - ie6offset
            });
          } else if (settings.vertical === 'scroll') {
            aaaie.$('html, body').animate(
              {
                scrollTop: top + clientHeight + 15 - viewportHeight
              },
              scrollSpeed
            );
          }
        }
        if ($elem.hasClass(aaaie.Help.config.dplCssClass)) {
          if ($elem.is('.tip-left, .tip-right')) {
            $elem.css({
              display: 'block',
              top: ''
            });
            var change = 0;
            if (top < aaaie.$(window).scrollTop()) {
              $elem.css({
                top: aaaie.$(window).scrollTop() - elemMarginTop
              });
              change = -($elem.offset().top - top);
              if (nubTop + change <= 10) {
                change = 0;
                nubTop = 15;
              }
            } else {
              $elem.css({
                top:
                  $(window).scrollTop() +
                  viewportHeight -
                  clientHeight -
                  elemMarginTop -
                  5
              });
              change = top - $elem.offset().top;
              if (
                nubTop + change >=
                clientHeight - elemMarginTop - $nub.outerHeight(true)
              ) {
                change = 0;
                nubTop = clientHeight - elemMarginTop - $nub.outerHeight(true);
              }
            }
            $nub.attr('style', 'top: ' + (nubTop + change) + 'px !important');
          } else {
            if (
              settings.vertical === 'move' ||
              top + clientHeight > aaaie.$(document).height()
            ) {
              var $target = aaaie.$(
                '.has-tip[data-selector="' + $elem.attr('data-selector') + '"]'
              );
              if (!$target.hasClass('tip-top')) {
                $target.removeClass('tip-bottom').addClass('tip-top');
                $elem.remove();
                Foundation.libs.tooltips.showOrCreateTip($target);
                var $anewElem = Foundation.libs.tooltips.getTip($target);
                $anewElem.attr('data-original-tip', 'tip-bottom');
              } else {
                $target.removeClass('tip-top').addClass('tip-bottom');
                $elem.remove();
                Foundation.libs.tooltips.showOrCreateTip($target);
                var $newElem = Foundation.libs.tooltips.getTip($target);
                $newElem.attr('data-original-tip', 'tip-top');
              }
            } else if (settings.vertical === 'scroll') {
              if ($elem.hasClass('tip-bottom'))
                aaaie.$('html, body').animate(
                  {
                    scrollTop: top + clientHeight + 20 - viewportHeight
                  },
                  scrollSpeed
                );
              else if ($elem.hasClass('tip-top'))
                aaaie.$('html, body').animate(
                  {
                    scrollTop: top
                  },
                  scrollSpeed
                );
            }
          }
        }
      } else {
        if (
          $elem.attr('data-original-tip') &&
          $elem.is('.tip-top, .tip-bottom')
        ) {
          var $atarget = aaaie.$(
            '.has-tip[data-selector="' + $elem.attr('data-selector') + '"]'
          );
          if ($elem.attr('data-original-tip') === 'tip-bottom')
            $atarget.removeClass('tip-top').addClass('tip-bottom');
          else if ($elem.attr('data-original-tip') === 'tip-top')
            $atarget.removeClass('tip-bottom').addClass('tip-top');
          $elem.remove();
          Foundation.libs.tooltips.showOrCreateTip($atarget);
        } else if (
          $elem.hasClass(aaaie.Help.config.dplCssClass) &&
          ($elem.hasClass('tip-left') || $elem.hasClass('tip-right'))
        ) {
          var $anub = $elem.find('.nub');
          $anub.css('top', nubTop);
        }
      }
    }

    if (settings.type === 'vertical' || settings.type === 'both') {
      moveVertical(elem);
    }
    if (settings.type === 'horizontal' || settings.type === 'both') {
      moveHorizontal(elem);
    }
  },

  /**
   * Renders a slider UI control.  Based on this open source control:
   *   http://refreshless.com/nouislider/
   * @param {Object} $el A jQuery element in which the slider will be rendered.
   * @param {Object} options An object with properties passed through to the nouislider. With addition of showTicks, tickStep, and units - for tickmarks.
   */
  slider: function($el, options) {
    var sliderConfig = aaaie.Widgets.config.slider;
    if (
      options.showToolTip &&
      (sliderConfig.upperToolTipFunc || sliderConfig.lowerToolTipFunc)
    ) {
      options.serialization = {};
      if (sliderConfig.upperToolTipFunc) {
        options.serialization.upper = [sliderConfig.upperToolTipFunc];
      }
      if (sliderConfig.lowerToolTipFunc) {
        options.serialization.lower = [sliderConfig.lowerToolTipFunc];
      }
    }
    if (options.nonLinear) {
      if (!sliderConfig.range) {
        throw 'For non-linear slider, range must be set in aaaie.Widgets.config.slider.range';
      }
      options.snap = true;
      options.range = sliderConfig.range;
    }

    $el.noUiSlider(options);

    (function setSliderTicks(elem, options) {
      if (options.showTicks) {
        var i,
          stepLabel,
          steps,
          spacing,
          frag = document.createDocumentFragment();
        if (options.nonLinear) {
          steps = Object.keys(options.range);
          for (i = 0; i < steps.length; i++) {
            stepLabel = options.range[steps[i]] + ' ' + options.units;
            if (steps[i] === 'min') {
              steps[i] = '0';
            } else if (steps[i] === 'max') {
              steps[i] = '100%';
            }
            aaaie
              .$(
                '<div class="aaaie-slider-tick-container"><span class="aaaie-slider-tick-mark"></span><br/><span class="aaaie-slider-label">' +
                  stepLabel +
                  '</span></div>'
              )
              .css('left', steps[i])
              .appendTo(frag);
          }
        } else {
          steps = (options.range.max - options.range.min) / options.tickStep;
          spacing = 100 / steps;
          // TODO: Can we get rid of this?  lots of HTML tags here in the script is not cool. -gstroup
          for (i = 0; i <= steps; i++) {
            stepLabel =
              options.range.min + i * options.tickStep + ' ' + options.units;
            aaaie
              .$(
                '<div class="aaaie-slider-tick-container"><span class="aaaie-slider-tick-mark"></span><br/><span class="aaaie-slider-label">' +
                  stepLabel +
                  '</span></div>'
              )
              .css('left', spacing * i + '%')
              .appendTo(frag);
          }
        }
        elem.append(frag);
      }
    })($el.find('.noUi-base'), options);
  },

  /*
   * Renders carousel, based on slick js
   * http://kenwheeler.github.io/slick/
   * @param {object} jquery selector in which carousel will render.
   * @param {object} defalut settings for carousel.
   */
  carousel: {
    render: function(element, settings) {
      settings = $.extend({}, aaaie.Widgets.config.carousel, settings);
      aaaie.$(element).slick(settings);
    },
    nextSlide: function(element) {
      $(element).slick('slickNext');
    },
    prevSlide: function(element) {
      $(element).slick('slickPrev');
    },
    goToSlide: function(element, index) {
      index = parseFloat(index);
      $(element).slick('slickGoTo', index);
    }
  }
};

//ZA: load before page ready to prevent flashing.  May cause issues depending on DOM/code structure.
aaaie
  .$('[data-' + aaaie.Widgets.config.displayVisible.attribute + ']')
  .each(function() {
    aaaie.Widgets.displayVisible(this, {
      type: aaaie
        .$(this)
        .attr('data-' + aaaie.Widgets.config.displayVisible.attribute),
      vertical: aaaie
        .$(this)
        .attr(
          'data-' + aaaie.Widgets.config.displayVisible.verticalActionAttribute
        ),
      horizontal: aaaie
        .$(this)
        .attr(
          'data-' +
            aaaie.Widgets.config.displayVisible.horizontalActionAttribute
        )
    });
  });

//TODO ??: Create a generic declarative framework for widgets
aaaie.$(document).ready(function() {
  'use strict';

  (function($) {
    var oldshow = $.fn.show;
    $.fn.show = function() {
      var displayVis = aaaie
        .$(this)
        .attr('data-' + aaaie.Widgets.config.displayVisible.attribute);
      if (displayVis && displayVis !== 'none') {
        aaaie.Widgets.displayVisible(this, {
          type: aaaie
            .$(this)
            .attr('data-' + aaaie.Widgets.config.displayVisible.attribute),
          vertical: aaaie
            .$(this)
            .attr(
              'data-' +
                aaaie.Widgets.config.displayVisible.verticalActionAttribute
            ),
          horizontal: aaaie
            .$(this)
            .attr(
              'data-' +
                aaaie.Widgets.config.displayVisible.horizontalActionAttribute
            )
        });
      }
      return oldshow.apply(this, arguments);
    };
  })(aaaie.$);

  aaaie.$('[data-aaaie-widget="dropdown"]').each(function() {
    aaaie.Widgets.dropdown(this, {
      contentId: aaaie
        .$(this)
        .attr(aaaie.Widgets.config.dropdown.contentIdAttribute)
    });
  });

  aaaie.$('[data-aaaie-widget="creditCard"]').each(function() {
    aaaie.Widgets.creditCard(this, {
      help: aaaie.$(this).attr('data-aaaie-widgetHelp'),
      errorMsg: aaaie.$(this).attr('data-error-widgetMessage'),
      inputName: aaaie.$(this).attr('data-aaaie-widgetInputName'),
      pattern: aaaie.$(this).attr('data-aaaie-widgetPattern'),
      mask: aaaie.$(this).attr('data-aaaie-widgetMask')
    });
  });

  aaaie.$('[data-aaaie-widget="page-message"]').each(function() {
    aaaie.Widgets.pageMessage(this, {
      template: aaaie.$(this).attr('data-aaaie-widget-message'),
      closeMessage: aaaie.$(this).attr('data-aaaie-widget-close-message'),
      closeAction: aaaie.$(this).attr('data-aaaie-widget-close-action')
    });
  });

  aaaie.$('[data-aaaie-widget="select-to-tabs"]').each(function() {
    aaaie.Widgets.selectToTabs(this);
  });

  aaaie.$('[data-aaaie-widget="accordion"]').each(function() {
    aaaie.Widgets.accordion(this, {});
  });

  aaaie.$('.aaaie-widgetCC').bind('validated', function(evt, validity) {
    var element = aaaie.$(this);
    if (validity.valid && aaaie.$('.ccValidate').length === 0) {
      var val = element.val().replace(/\D/g, '');
      if (val.match(/^4[0-9]{12}(?:[0-9]{3})?$/)) {
        //Visa
        element.after('<span class="ccValidate visaImage"/>');
      } else if (
        val.match(/^5[1-5][0-9]{14}$/) ||
        val.match(/^2[1-5][0-9]{14}$/)
      ) {
        //MasterCard
        element.after('<span class="ccValidate masterCardImage"/>');
      } else if (val.match(/^3[47][0-9]{13}$/)) {
        //Amex
        element.after('<span class="ccValidate amexImage"/>');
      } else if (val.match(/^3(?:0[0-5]|[68][0-9])[0-9]{11}$/)) {
        //Diners Club
        element.after('<span class="ccValidate dinersClubImage"/>');
      } else if (val.match(/^6(?:011|5[0-9]{2})[0-9]{12}$/)) {
        //Discover
        element.after('<span class="ccValidate discoverImage"/>');
      } else if (val.match(/^(?:2131|1800|35\d{3})\d{11}$/)) {
        //JCB
        element.after('<span class="ccValidate jcbImage"/>');
      } else {
        element.nextAll('.ccValidate').remove();
      }
    } else if (!validity.valid) {
      element.nextAll('.ccValidate').remove();
    }
  });

  aaaie.$('[data-aaaie-widget="slider"]').each(function() {
    var el = aaaie.$(this),
      options = {};
    options.start = [Number(el.attr('data-aaaie-widget-slider-start'))];
    if (el.attr('data-aaaie-widget-slider-step')) {
      options.step = Number(el.attr('data-aaaie-widget-slider-step'));
    }
    if (el.attr('data-aaaie-widget-slider-showticks')) {
      options.showTicks = el.attr('data-aaaie-widget-slider-showticks');
    }
    if (el.attr('data-aaaie-widget-slider-units')) {
      options.units = el.attr('data-aaaie-widget-slider-units');
    }
    if (el.attr('data-aaaie-widget-slider-tickstep')) {
      options.tickStep = Number(el.attr('data-aaaie-widget-slider-tickstep'));
    } else {
      options.tickStep = Number(el.attr('data-aaaie-widget-slider-step'));
    }
    if (el.attr('data-aaaie-widget-slider-show-tooltip')) {
      options.showToolTip = true;
    }
    if (el.attr('data-aaaie-widget-slider-nonlinear')) {
      options.nonLinear = true;
    }
    // extend slider bar, so that handle stays inside. also allow tap on slider bar.
    options.behaviour = 'extend-tap';
    options.range = {};
    options.range.min = Number(el.attr('data-aaaie-widget-slider-min'));
    options.range.max = Number(el.attr('data-aaaie-widget-slider-max'));

    aaaie.Widgets.slider(el, options);
  });

  aaaie.$('[data-aaaie-widget="carousel"]').each(function() {
    aaaie.Widgets.carousel.render(this, {});
  });
});

/**
 * Show Progress Bar on AJAX calls.
 * Declarative Progress Bar settings - originally conceived from EZ Pay project
 */
aaaie.$(document).ajaxStart(function() {
  aaaie.$('.progress').show();
  aaaie.$('.progress span').animate(
    {
      width: '100%'
    },
    800,
    function() {
      aaaie.$('.progress span').css('width', '0');
    }
  );
});

/**
 * Hide Progress Bar on AJAX completions.
 * Declarative Progress Bar settings - originally conceived from EZ Pay project
 */
aaaie.$(document).ajaxComplete(function() {
  aaaie.$('.progress').hide();
});

//TODO: Need event handlers for AJAX Error as well - globally hide/show Progress or Failure messages.

/**
 * Implementation of Pagination for use within the JCL.
 */
Handlebars.registerHelper('paginate', function(pagination, options) {
  'use strict';
  var type = options.hash.type || 'middle';
  var ret = '';
  var pageCount = pagination.pageCount;
  var page = pagination.page;
  var limit;
  if (options.hash.limit) {
    limit = +options.hash.limit;
  }

  //page pageCount
  var newContext = {};
  switch (type) {
    //ZA: Added second property (p) to newContext object to account for ellipses
    case 'middle':
      if (typeof limit === 'number') {
        var i = 0;
        var leftCount = Math.ceil(limit / 2) - 1;
        var rightCount = limit - leftCount - 1;
        if (page + rightCount > pageCount) {
          leftCount = limit - (pageCount - page) - 1;
        }
        if (page - leftCount < 1) {
          leftCount = page - 1;
        }
        if (leftCount + rightCount + 1 < limit) {
          rightCount = limit - leftCount - 1;
        }
        var start = page - leftCount;
        //ZA: Added to display first page and beginning ellipses as necessary
        if (page - leftCount > 1) {
          newContext = {
            p: 1,
            n: 1
          };
          ret = ret + options.fn(newContext);
          if (page - leftCount > 2) {
            newContext = {
              disabled: true,
              p: '...',
              n: page - leftCount - 1
            };
            ret = ret + options.fn(newContext);
          }
        }
        while (i < limit && i < pageCount) {
          newContext = {
            n: start,
            p: start
          };
          if (start === page) {
            newContext.active = true;
          }
          ret = ret + options.fn(newContext);
          start++;
          i++;
        }
        //ZA: Added to display the ellipses and last page as necessary
        if (page + rightCount < pageCount) {
          if (page + rightCount + 1 < pageCount) {
            newContext = {
              disabled: true,
              p: '...',
              n: page + rightCount + 1
            };
            ret = ret + options.fn(newContext);
          }

          newContext = {
            p: pageCount,
            n: pageCount
          };
          ret = ret + options.fn(newContext);
        }
      } else {
        for (var k = 1; k <= pageCount; k++) {
          newContext = {
            n: k,
            p: k
          };
          if (k === page) {
            newContext.active = true;
          }
          ret = ret + options.fn(newContext);
        }
      }
      break;
    case 'previous':
      if (page === 1) {
        newContext = {
          disabled: true,
          n: 1
        };
      } else {
        newContext = {
          n: page - 1
        };
      }
      ret = ret + options.fn(newContext);
      break;
    case 'next':
      newContext = {};
      if (page === pageCount) {
        newContext = {
          disabled: true,
          n: pageCount
        };
      } else {
        newContext = {
          n: page + 1
        };
      }
      ret = ret + options.fn(newContext);
      break;
  }

  return ret;
});
