aaaie.expander = {
  ready: true,
  expand: function(container, card) {
    var $container = aaaie.$('.' + container);
    $container.on('click', '.' + card + ' .maximize', function(ev) {
      if (!aaaie.expander.ready) return;
      var currentCard = $(ev.currentTarget).parents('.' + card);
      $('.' + card + ', .IKCAlerts')
        .not(currentCard.get(0))
        .addClass('disappear');
      if (checkIE()) {
        $('.' + card)
          .not(currentCard.get(0))
          .hide();
      }
      if (currentCard[0].classList.contains('customer-events')) {
        $('.customer-events .core-maximize-medium')
          .removeClass('core-maximize-medium')
          .addClass('core-close-medium');
        $('.customer-events .maximize')
          .removeClass('maximize')
          .addClass('minimize');
        $('.large-9').removeClass('large-9', 500, 'easeInBack');
      } else if (currentCard[0].classList.contains('forms-documents')) {
        $('.large-9').removeClass('large-9', { duration: 1000 });
        var content = $('.content');
        content.addClass('forms-documents-display');
        content.addClass('filter-display');
      } else if (currentCard[0].classList.contains('news-alerts')) {
        $('.large-3').removeClass('large-3', 500, 'easeInBack');
        $('.news-column').removeClass('medium-6');
      } else if (currentCard[0].classList.contains('activity-history')) {
        $('.large-3').removeClass('large-3', { duration: 1000 });
      } else if (currentCard[0].classList.contains('new-paperless')) {
        $('.large-3').removeClass('large-3', { duration: 1000 });
      } else if (currentCard[0].classList.contains('customer-documents')) {
        var currentContainer = $('aaaie-b2b-documents').parents('.medium-12');
        if (currentContainer[0].classList.contains('right-well')) {
          currentContainer.removeClass('large-3', { duration: 1000 });
        } else {
          currentContainer.removeClass('large-9', { duration: 1000 });
        }
      } else if (currentCard[0].classList.contains('claims')) {
        $('.large-3').removeClass('large-3', { duration: 1000 });
      }
      currentCard.addClass('expand').removeClass('shrink');
    });
    $container.on('click', '.' + card + ' .minimize', function(ev) {
      //rerender carousel if present, needed in case of user resized the browser window after maximizing a card
      var currentCard = $(ev.currentTarget).parents('.' + card);
      aaaie.expander.minimize(currentCard, card);
    });
  },

  minimize: function(currentCard, card) {
    if (currentCard[0].classList.contains('customer-events')) {
      //currentCard.removeClass('expand').addClass('shrink');
      $('.customer-events .core-close-medium')
        .removeClass('core-close-medium')
        .addClass('core-maximize-medium');
      $('.customer-events .minimize')
        .removeClass('minimize')
        .addClass('maximize');
      $('.IKCAlerts').removeClass('hide');
      currentCard.parents('.medium-12').addClass('large-9', { duration: 1000 });
    } else if (currentCard[0].classList.contains('forms-documents')) {
      //$(".desktop-divider").css({'width':($(".documents").width()+'px')});
      currentCard.parents('.medium-12').addClass('large-9', { duration: 1000 });
      //currentCard.removeClass('expand').addClass('shrink');
      var detailClass = document.querySelector('.content');
      detailClass.classList.remove('forms-documents-display');
      detailClass.classList.remove('content-full-width');
      detailClass.classList.remove('filter-display');
    } else if (currentCard[0].classList.contains('news-alerts')) {
      //currentCard.removeClass('expand').addClass('shrink');
      currentCard.parents('.medium-12').addClass('large-3', { duration: 1000 });
      $('.news-column').addClass('medium-6');
    } else if (
      currentCard[0].classList.contains('activity-history') ||
      currentCard[0].classList.contains('new-paperless') ||
      currentCard[0].classList.contains('claims')
    ) {
      currentCard.parents('.medium-12').addClass('large-3');
    } else if (currentCard[0].classList.contains('customer-documents')) {
      var currentContainer = $('aaaie-b2b-documents').parents('.medium-12');
      if (currentContainer[0].classList.contains('right-well')) {
        currentCard.parents('.medium-12').addClass('large-3');
      } else {
        currentCard.parents('.medium-12').addClass('large-9');
      }
    }
    currentCard.removeClass('expand').addClass('shrink');
    $('.disappear').removeClass('shrink');
    $('.' + card + ', .IKCAlerts')
      .not(currentCard.get(0))
      .removeClass('disappear');
    if (checkIE()) {
      $('.' + card)
        .not(currentCard.get(0))
        .show();
    }

    if (aaaie.Elements.Carousel && !aaaie.Elements.Documents.isPup) {
      aaaie.Elements.Carousel.renderHtml();
    }
  }
};

var checkIE = function() {
  var res;
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    res = 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem !== null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) !== null) M.splice(1, 1, tem[1]);
  res = M.join(' ');
  return res === 'MSIE 9';
};

checkIE = deprecate(
  checkIE,
  'checkIE() is deprecated. Avoid browser detection.'
);
