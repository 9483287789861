aaaie.Elements = {
  config: {
    s3ElementPath: 'https://s3.amazonaws.com/csaa-ds-releases/assets/elements/',
    elementPath: '/assets/elements/', //requires trailing slash unless empty string ZA
    versionAttribute: 'version',
    elementAttribute: 'element'
  },

  register: function(elementName, prototype, content) {
    //Load templates if src attribute specified - TODO move this to Node.JS and precompile the templates, then cache them client side ZA
    var link = document.querySelector('link[id="' + elementName + '"]');
    if (!link) return;
    var templates = link['import'].querySelectorAll('template[src]');
    //Our link parsing function in the JCL is broken, it won't properly parse URLs with periods in the path...which are valid URI path chars
    var linkPath = link.href
      .replace(document.location.origin, '')
      .replace(/[^/]+$/, '');
    //var linkPath = link.getAttribute('href').replace(/[^\/]+$/, '');
    // For IE, the pathname is missing the leading slash. Manually append the / to fix the issue
    if (
      linkPath !== null &&
      linkPath.charAt(0) !== '/' &&
      linkPath.substring(0, 'http'.length) !== 'http'
    ) {
      linkPath = '/' + linkPath;
    }
    //TODO separate register into another function, load the template if needed, then call the other function.  var cont is a quick hack. ZA
    var cont = true;
    if (!aaaie.Elements[elementName]) {
      aaaie.Elements[elementName] = {
        elRetrieved: [],
        elReceived: []
      };
    }
    aaaie.$.each(templates, function(i, template) {
      if (
        template.getAttribute('src') &&
        aaaie.Elements[elementName].elRetrieved.indexOf(
          template.getAttribute('src')
        ) === -1
      ) {
        aaaie.Elements[elementName].elRetrieved.push(
          template.getAttribute('src')
        );
        cont = false;
        aaaie.Xhr.get(linkPath + template.getAttribute('src')).done(function(
          data
        ) {
          $(template).html(data.data);
          //TODO calls may not come back in order, when refactoring into separate function change this to a better promise structure ZA
          //Yea, they didn't come back in order, and it created an inconsistent problem where the custom element was blank because the templates weren't fully loaded ZA
          //Added received array to fix the load order issue ZA
          aaaie.Elements[elementName].elReceived.push(
            template.getAttribute('src')
          );
          if (
            aaaie.Elements[elementName].elReceived.length ===
            aaaie.Elements[elementName].elRetrieved.length
          ) {
            return aaaie.Elements.register(elementName, prototype, content);
          }
        });
      }
    });

    var inlineTemplates = link['import'].querySelectorAll(
      'template:not([src])'
    );
    aaaie.$.each(inlineTemplates, function(i, template) {
      if (template.innerHTML.length === 0) {
        var div = document.createElement('div');
        div.appendChild(template.content);
        aaaie.$(template).html(div.innerHTML);
      }
    });
    if (!cont) return;

    var element;
    if (prototype) element = Object.create(prototype);
    else {
      element = Object.create(HTMLElement.prototype);

      if (!content)
        content = document
          .querySelector('link[id="' + elementName + '"]')
          .import.querySelector('#' + elementName);
      if (!content) content = document.querySelector('#' + elementName);
      if (!content) {
        // eslint-disable-next-line no-console
        console.warn(
          'Template could not be found and was not provided for custom element: ' +
            elementName
        );
      } else {
        Object.defineProperty(element, 'template', {
          value: content.innerHTML,
          writable: false,
          enumerable: true,
          configurable: true
        });
      }

      //create content automatically if render="true" attribute is set on element
      element.createdCallback = function() {
        aaaie.$.when(aaaie.$(this).trigger('customElementCreated', this)).done(
          function() {
            if (this.context.getAttribute('render') === 'true') {
              //if dataPath attribute exists, call it, then render template else just render
              if (
                this.context.getAttribute('datapath') ||
                this.context.dataPath
              ) {
                this.context.getData(true);
              } else {
                if (!this.context.data) this.context.data = {};
                this.context.render();
              }
            } else if (
              this.context.getAttribute('datapath') ||
              this.context.dataPath
            ) {
              this.context.getData(false);
            }
          }
        );
      };

      //look for data on element if not passed, render and set element content to output
      element.render = function(data) {
        data = data || this.data;
        if (!data)
          // eslint-disable-next-line no-console
          console.warn(
            'Data could not be found and was not provided for custom element: ' +
              elementName
          );
        if (!this.template)
          // eslint-disable-next-line no-console
          console.warn(
            'Template could not be found and was not provided for custom element: ' +
              elementName
          );
        else {
          aaaie.$.when(
            aaaie.$(this).trigger('rendering', {
              template: this.template,
              data: data
            })
          ).done(function() {
            if ('function' === typeof this.context.template)
              this.context.innerHTML = this.context.template(data);
            else {
              this.context.innerHTML += aaaie.Templating.render(
                this.context.template,
                data
              );
              aaaie.Elements.findCustomElements(this.context);
            }
          });
        }
      };

      //call dataPath with optional postData, optionally call render when done
      element.getData = function(
        render,
        dataPath,
        postData,
        type,
        contentType
      ) {
        var el = this;
        dataPath = dataPath || this.dataPath || this.getAttribute('datapath');
        if (!dataPath)
          // eslint-disable-next-line no-console
          console.warn('Datapath not found for custom element: ' + elementName);
        //TODO add loading spinner ZA
        aaaie.$.ajax({
          url: dataPath,
          type: type || (postData ? 'POST' : 'GET'),
          data: postData,
          contentType: contentType || 'application/json; charset=UTF-8',
          success: function(data) {
            el.data = data;
            aaaie.$.when(aaaie.$(el).trigger('dataReceived', data)).done(
              function() {
                if (render) this.context.render();
              }
            );
          },
          error: function(xhr, status, error) {
            // eslint-disable-next-line no-console
            console.warn(
              'Data could not be retrieved for custom element: ' +
                elementName +
                ', ERROR: ' +
                error
            );
            aaaie.$(el).trigger('dataReceiveError', {
              xhr: xhr,
              status: status,
              error: error
            });
          }
        });
      };

      element.attachedCallback = function() {
        aaaie.$(this).trigger('customElementAttached', this);
      };
      element.detachedCallback = function() {
        aaaie.$(this).trigger('customElementDetached', this);
      };
      element.attributeChangedCallback = function(attribute) {
        aaaie.$(this).trigger('customElementAttributeChanged', attribute);
      };
    }

    return document.registerElement(elementName, {
      prototype: element
    });
  },

  /*
   * Naive implementation of a Custom Element
   * scanner. This is specifically looking for elements
   * that start with the "aaaie-" prefix.
   */
  buildLink: function(node) {
    var rootPath = node.S3
      ? aaaie.Elements.config.s3ElementPath
      : aaaie.Elements.config.elementPath;
    var elementPath =
      node.name + '/' + node.version + '/' + node.name + '-' + node.version;
    return rootPath + elementPath;
  },

  pullAttributes: function(node) {
    var attrs = {};
    attrs.version = node.getAttribute('version');
    attrs.name = node.nodeName.toLowerCase();
    attrs.S3 = node.getAttribute('S3') === 'true';
    // indicates modern elements built with react which don't need html templates
    attrs.react = node.getAttribute('data-react') === 'true';
    return attrs;
  },

  findCustomElements: function(node) {
    var elements = [],
      child,
      id = 'aaaie-',
      children = [],
      allTags =
        node === document
          ? node.body.getElementsByTagName('*')
          : node.getElementsByTagName('*');

    for (var i = 0, length = allTags.length; i < length; i++) {
      child = allTags[i];
      if (
        child.nodeName &&
        child.nodeName.substr(0, id.length).toLowerCase() === id
      ) {
        var attrs = aaaie.Elements.pullAttributes(child);
        var elementId = attrs.name + '-' + attrs.version;
        if (-1 === aaaie.$.inArray(elementId, elements)) {
          elements.push(elementId);
          children.push(child);
        }
      }
    }
    aaaie.Elements.sortByPriority(children);
    aaaie.Elements.importElements(children);

    return elements;
  },

  importElements: function(list) {
    list.forEach(function(value) {
      aaaie.Elements.addElementImport(value);
    });
  },

  sortByPriority: function(list) {
    list.sort(function(a, b) {
      var prev = a.getAttribute('priority') || 0;
      var next = b.getAttribute('priority') || 0;
      return prev - next;
    });
  },

  /*
   * Creates a Link element used for HTML Importing the
   * Custom Element registration code.
   *
   * Will also execute a polyfill for Template.
   */
  addElementImport: function(node) {
    var attrs = aaaie.Elements.pullAttributes(node);
    var url = aaaie.Elements.buildLink(attrs);
    var link = document.createElement('link');
    if (attrs.react) {
      // when react, get script and css files directly
      var script = document.createElement('script');
      script.src = url + '.js';
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = url + '.css';
      document.head.appendChild(script);
      document.head.appendChild(link);
    } else {
      link.id = attrs.name;
      link.rel = 'import';
      link.href = url + '.html';
      link.onload = function() {
        if (aaaie.$(attrs.name).attr('register') === 'true') {
          aaaie.Elements.register(attrs.name);
        }
        // move all stylesheet links to parent document
        // https://github.com/TakayoshiKochi/deprecate-style-in-html-imports
        Array.prototype.slice
          .call(link.import.querySelectorAll('link[rel=stylesheet]'))
          .forEach(function(linkTag) {
            // fixes issue where href is not actually absolute but gets returned as absolute url by getter
            // eslint-disable-next-line no-self-assign
            linkTag.href = linkTag.href;
            linkTag.type = 'text/css';
            aaaie.$(linkTag).detach();
            document.head.appendChild(linkTag);
          });
      };
      document.head.appendChild(link);
    }
  },

  getCustomElementTagNames: function(node) {
    var elements = this.findCustomElements(node);
    var names = [];
    var _name;

    for (var i = 0; i < elements.length; i++) {
      _name = elements[i].replace(/-(\d*\.){2}\d*/, '');
      names.push(_name);
    }

    return names;
  },

  hideAllCustomElements: function() {
    var elements = aaaie.Elements.getCustomElementTagNames(document);

    $.each(elements, function() {
      aaaie.$.each(elements, function(idx, element) {
        aaaie.$(element).hide();
      });
    });
  }
};

aaaie.$(document).ready(function() {
  aaaie.Elements.findCustomElements(document);
});
